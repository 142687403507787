import type { FunctionComponent } from 'react';
import cx from 'classnames';

import {
  formatOrdinalDay,
  longDateWithoutYearFormatter,
  numericDateFormatter,
} from '@trello/dates/i18n';
import {
  asMoney,
  asPercentage,
  forTemplate,
  localizeCount,
} from '@trello/legacy-i18n';
import type { PIIString } from '@trello/privacy';
import { EMPTY_PII_STRING } from '@trello/privacy';
import type { PurchaseFormIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import { taxRegionLabel } from 'app/src/components/CreditCardForm/taxes';
import { SectionMessage } from 'app/src/components/SectionMessage';

import styles from './PlanSummary.less';

const format = forTemplate('credit_card');
const formatNewBilling = forTemplate('billing_page_one');

interface LicenseDescriptionObject {
  numberOfLicenses: string;
  pricePerLicense: string;
}

interface BasePlanSummaryProps {
  country?: PIIString;
  zipCode?: PIIString;
  userCount?: number;
  billableCollaboratorCount?: number;
  licenseDescription?: string;
  licenseDescriptionObj?: LicenseDescriptionObject;
  tax?: number | null;
  taxRegion?: string | null;
  subtotal: number;
  subtotalPerUser: number;
  total?: number | null;
  pendingInvitations?: number;
  calculationError?: boolean;
  isAnnual?: boolean;
  isDowngrade?: boolean;
  nextBillDate?: string;
}

interface PlanSummaryWithDiscountProps extends BasePlanSummaryProps {
  priceAdjustment: number;
  adjustedSubtotal: number;
  adjustedSubtotalPerUser: number;
}

export type PlanSummaryProps =
  | BasePlanSummaryProps
  | PlanSummaryWithDiscountProps;

export const PlanSummary: FunctionComponent<PlanSummaryProps> = ({
  country = '',
  zipCode = EMPTY_PII_STRING,
  userCount,
  billableCollaboratorCount = 0,
  licenseDescription,
  licenseDescriptionObj,
  tax = 0,
  taxRegion = '',
  subtotal,
  subtotalPerUser,
  total,
  pendingInvitations = 0,
  calculationError,
  isAnnual,
  isDowngrade,
  nextBillDate,
  ...props
}) => {
  const {
    priceAdjustment = 1,
    adjustedSubtotal = subtotal,
    adjustedSubtotalPerUser = subtotalPerUser,
  } = props as PlanSummaryWithDiscountProps;
  const isDiscounted = priceAdjustment < 1;
  let pendingInvitationsDescriptionKey;
  if (typeof tax !== 'number') {
    pendingInvitationsDescriptionKey =
      'pending-invitation-plus-tax-if-applicable-billing-description';
  } else if (tax > 0) {
    pendingInvitationsDescriptionKey =
      'pending-invitation-plus-tax-billing-description';
  } else {
    pendingInvitationsDescriptionKey = 'pending-invitation-billing-description';
  }
  const showSavings = !isAnnual && !isDowngrade;
  const showNextBillDate = isDowngrade && Boolean(nextBillDate);

  const taxLabel: ReturnType<typeof format> | string = taxRegionLabel(
    tax,
    country,
    zipCode,
    taxRegion,
  );

  const parsedNextBillDate = nextBillDate ? new Date(nextBillDate) : null;

  return (
    <>
      <table
        className={cx(styles.summary, styles.nuskuSummaryTable)}
        role="presentation"
      >
        <tbody>
          <tr>
            <td
              data-testid={getTestId<PurchaseFormIds>(
                'purchase-form-summary-license',
              )}
            >
              <div>
                <span className={styles.nuskuLicenseDescription}>
                  {licenseDescriptionObj?.numberOfLicenses ||
                    licenseDescription}
                </span>
                <br />
                {licenseDescriptionObj?.pricePerLicense}
              </div>
              {billableCollaboratorCount <= 0 ? null : (
                <div className={styles.multiBoardGuestLineItem}>
                  {formatNewBilling('includes')}
                  <ul>
                    <li>{localizeCount('team-members', userCount)}</li>
                    <li>
                      {localizeCount(
                        'multi-board-guests',
                        billableCollaboratorCount,
                      )}
                    </li>
                  </ul>
                </div>
              )}
            </td>
            <td>
              <div>
                <strong
                  className={cx({
                    [styles.strikeThrough]: isDiscounted,
                  })}
                  data-testid={getTestId<PurchaseFormIds>(
                    'purchase-form-summary-subtotal',
                  )}
                >
                  {format('amount-usd', {
                    amount: asMoney(subtotal),
                  })}
                </strong>
                {isDiscounted && (
                  <div
                    className={styles.discountedPrice}
                    data-testid={getTestId<PurchaseFormIds>(
                      'purchase-form-summary-discount',
                    )}
                  >
                    <strong
                      data-testid={getTestId<PurchaseFormIds>(
                        'purchase-form-summary-discount-value',
                      )}
                    >
                      {format('amount-usd', {
                        amount: asMoney(adjustedSubtotal),
                      })}
                    </strong>
                    <br />
                    <span className={styles.discountApplied}>
                      {format('percent-discount-applied', {
                        discountPercent: asPercentage(1 - priceAdjustment),
                      })}
                    </span>
                  </div>
                )}
                {showSavings && (
                  <>
                    <br />
                    <span className={styles.nuskuSavePercentage}>
                      {formatNewBilling('save-with-annual', {
                        percentage: 20,
                      })}
                    </span>
                  </>
                )}
              </div>
            </td>
          </tr>
          <tr>
            {calculationError ? (
              <td
                colSpan={2}
                data-testid={getTestId<PurchaseFormIds>(
                  'purchase-form-summary-tax-error',
                )}
              >
                <div className={styles.errorMessage}>
                  {format('could-not-calculate-sales-tax')}
                </div>
              </td>
            ) : (
              <>
                <td
                  data-testid={getTestId<PurchaseFormIds>(
                    'purchase-form-summary-tax-region',
                  )}
                  className={styles.salesTax}
                >
                  {taxLabel}
                </td>
                <td
                  data-testid={getTestId<PurchaseFormIds>(
                    'purchase-form-summary-tax-amount',
                  )}
                  className={styles.salesTax}
                >
                  {typeof tax === 'number'
                    ? format('amount-usd', { amount: asMoney(tax) })
                    : format('tax-if-applicable')}
                </td>
              </>
            )}
          </tr>
        </tbody>
        {typeof total === 'number' && (
          <tfoot>
            <tr>
              <td>{format('total-label')}</td>
              <td
                data-testid={getTestId<PurchaseFormIds>(
                  'purchase-form-summary-total',
                )}
              >
                {format('amount-usd', { amount: asMoney(total) })}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {showNextBillDate && parsedNextBillDate && (
        <div className={styles.nextBillDate}>
          <span
            data-testid={getTestId<PurchaseFormIds>(
              'purchase-form-summary-bill-date',
            )}
          >
            {isAnnual
              ? formatNewBilling('billing-next-bill-annual', {
                  dayOfYear:
                    longDateWithoutYearFormatter.format(parsedNextBillDate),
                  nextBillDate: numericDateFormatter.format(parsedNextBillDate),
                })
              : formatNewBilling('billing-next-bill-monthly', {
                  dayOfMonth: formatOrdinalDay(parsedNextBillDate.getDate()),
                  nextBillDate: numericDateFormatter.format(parsedNextBillDate),
                })}
          </span>
        </div>
      )}
      {pendingInvitations > 0 && (
        <div className={styles.pendingInvitations}>
          <SectionMessage
            testId={getTestId<PurchaseFormIds>(
              'purchase-form-summary-pending-invitations',
            )}
          >
            {isDowngrade
              ? localizeCount(
                  'pending-invitations-downgrade',
                  pendingInvitations,
                  {
                    price: format('amount-usd', {
                      amount: asMoney(adjustedSubtotalPerUser),
                    }),
                  },
                )
              : localizeCount(
                  'pending-invitations-on-team',
                  pendingInvitations,
                )}{' '}
            {!isDowngrade &&
              format(pendingInvitationsDescriptionKey, {
                amount: (
                  <span
                    className={styles.pendingInvitationPrice}
                    key="pending-invitation-price"
                  >
                    {isDiscounted ? (
                      <>
                        <span className={styles.strikeThrough}>
                          ${asMoney(subtotalPerUser)}
                        </span>{' '}
                        <span>
                          {format('amount-usd', {
                            amount: asMoney(adjustedSubtotalPerUser),
                          })}
                        </span>
                      </>
                    ) : (
                      format('amount-usd', {
                        amount: asMoney(adjustedSubtotalPerUser),
                      })
                    )}
                  </span>
                ),
              })}
          </SectionMessage>
        </div>
      )}
    </>
  );
};
