import { useCallback, useRef } from 'react';

import { Analytics } from '@trello/atlassian-analytics';
import { getScreenFromUrl } from '@trello/marketing-screens';
import { useSharedStateSelector } from '@trello/shared-state';
import { workspaceState } from '@trello/workspace-state';

import type {
  ExperimentVariations,
  FeatureExperimentKeys,
  FeatureExperimentParameters,
  FeatureLayerParameters,
  FeatureLayersKeys,
} from './data/featureGates';
import {
  getFeatureGatesClientCache,
  getWorkspaceCacheKey,
} from './state/featureGatesClientSharedState';
import { useFireExposureEvent } from './useFireExposureEvent';
import { useIsFeatureGateClientInitializeCompleted } from './useIsFeatureGateClientInitializeCompleted';

const notEnrolled = 'not-enrolled';

export interface UseGetExperimentValueArgs<K, P> {
  experimentName: K;
  parameter: P;
  fireExposureEvent?: boolean;
  options?: {
    isLayer: boolean;
  };
}

export const useGetExperimentValueWithRefresh = <
  K extends FeatureExperimentKeys,
  P extends FeatureExperimentParameters<K>,
>({
  experimentName,
  parameter,
  fireExposureEvent,
  options = { isLayer: false },
}: UseGetExperimentValueArgs<K, P>): Record<P, ExperimentVariations<K, P>> & {
  loading: boolean;
} => {
  const isFeatureGateClientInitializeCompleted =
    useIsFeatureGateClientInitializeCompleted();

  const { workspaceId } = useSharedStateSelector(
    workspaceState,
    useCallback(
      (state) => ({
        workspaceId: state.workspaceId,
      }),
      [],
    ),
  );

  const prevExperimentConfigAttribute = useRef<{
    experimentName: K;
    workspaceKey: string;
    isValueSetForWorkspace: boolean;
    experimentConfig: object | undefined;
    parameter: ExperimentVariations<K, P> | undefined;
  }>();

  const sharedStateExperimentValue = useSharedStateSelector(
    getFeatureGatesClientCache(),
    useCallback(
      (updateState) => {
        if (options.isLayer) {
          return updateState[getWorkspaceCacheKey(workspaceId)]?.layers?.[
            experimentName as FeatureLayersKeys
          ]?.[parameter as FeatureLayerParameters<FeatureLayersKeys>];
        }

        const experimentConfigAttribute = {
          experimentName,
          workspaceKey: getWorkspaceCacheKey(workspaceId),
          isValueSetForWorkspace:
            !!updateState[getWorkspaceCacheKey(workspaceId)],
          experimentConfig:
            updateState[getWorkspaceCacheKey(workspaceId)]?.configs[
              experimentName
            ],
          parameter:
            updateState[getWorkspaceCacheKey(workspaceId)]?.configs[
              experimentName
            ]?.[parameter],
        };

        const sharedStateParameterValue =
          updateState[getWorkspaceCacheKey(workspaceId)]?.configs[
            experimentName
          ]?.[parameter];

        if (
          (sharedStateParameterValue === undefined ||
            sharedStateParameterValue === null) &&
          prevExperimentConfigAttribute.current?.parameter !== undefined && // This hook returned a valid experiment value before.
          fireExposureEvent
        ) {
          Analytics.sendOperationalEvent({
            action: 'retrieved',
            actionSubject: 'experiment',
            attributes: {
              ...experimentConfigAttribute,
              prevExperimentConfigAttribute:
                prevExperimentConfigAttribute.current,
            },
            source: getScreenFromUrl(),
          });
        }
        prevExperimentConfigAttribute.current = experimentConfigAttribute;

        return sharedStateParameterValue;
      },
      [
        experimentName,
        fireExposureEvent,
        options.isLayer,
        parameter,
        workspaceId,
      ],
    ),
  );

  const experimentValue = sharedStateExperimentValue ?? notEnrolled;

  useFireExposureEvent({
    experimentName,
    value: experimentValue,
    fireExposureEvent,
    options: options.isLayer
      ? {
          isLayer: true,
          parameterName: parameter,
        }
      : undefined,
  });

  // type casted because parameter is not recognized to be of type P.
  const parameterObj = {
    [parameter as P]: experimentValue,
  } as Record<P, ExperimentVariations<K, P>>;

  return {
    ...parameterObj,
    loading: !isFeatureGateClientInitializeCompleted,
  };
};
