import { Analytics } from '@trello/atlassian-analytics';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { fetch, trelloFetch } from '@trello/fetch';
import { parseNetworkError } from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type {
  BoardDashboardViewTileArgs,
  BoardExportArgs,
  BoardStatsArgs,
  MutationAddBoardArgs,
  MutationAddMemberToBoardArgs,
  MutationCreateBoardInviteSecretArgs,
  MutationDeleteBoardArgs,
  MutationDisableBoardInviteSecretArgs,
  MutationEnablePluginArgs,
  MutationMessageEmailKeyArgs,
  MutationRemoveMemberFromBoardArgs,
  MutationStartBoardExportArgs,
  MutationUpdateBoardArgs,
  MutationUpdateBoardCardCoversPrefArgs,
  MutationUpdateBoardMemberPermissionArgs,
  MutationUpdateBoardMyPrefsArgs,
  MutationUpdateBoardOrgArgs,
  MutationUpdateBoardSwitcherViewsPrefArgs,
  MutationUpdateBoardVisibilityArgs,
  MutationUpdateCalendarFeedEnabledPrefArgs,
  MutationUpdateCalendarKeyArgs,
  MutationUpdateEmailKeyArgs,
  MutationUpdateEmailPositionArgs,
  MutationUpdateHiddenPluginBoardButtonShortcutsArgs,
  QueryBoardsSearchArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { TrelloRestResolver } from '../types';

export const addBoard: TrelloRestResolver<MutationAddBoardArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const { traceId, ...body } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl('/1/boards');
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      ...body,
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const board = await response.json();

  return prepareDataForApolloCache(board, rootNode);
};

export const enablePlugin: TrelloRestResolver<
  MutationEnablePluginArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/boards/${args.boardId}/boardPlugins`);
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      idPlugin: args.pluginId,
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const updateBoardOrg: TrelloRestResolver<
  MutationUpdateBoardOrgArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/boards/${args.boardId}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      keepBillableGuests: args.keepBillableGuests,
      idOrganization: args.orgId,
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const updateBoardVisibility: TrelloRestResolver<
  MutationUpdateBoardVisibilityArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/boards/${args.boardId}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      keepBillableGuests: args.keepBillableGuests,
      'prefs/permissionLevel': args.visibility,
      idOrganization: args.orgId,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const updateBoardCardCoversPref: TrelloRestResolver<
  MutationUpdateBoardCardCoversPrefArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}`),
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        'prefs/cardCovers': args.cardCovers,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const updateBoardSwitcherViewsPref: TrelloRestResolver<
  MutationUpdateBoardSwitcherViewsPrefArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}`),
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        'prefs/switcherViews': args.switcherViews,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const boardExportResolver: TrelloRestResolver<BoardExportArgs> = async (
  board: {
    id: string; // idBoard
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/boards/${board.id}/exports/${args.id}`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Board.export',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
    } else {
      if (response.status === 404) {
        model = null;
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    return model ? prepareDataForApolloCache(model, rootNode, 'Board') : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const startBoardExport: TrelloRestResolver<
  MutationStartBoardExportArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.id}/exports`),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
        attachments: true,
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const statsResolver: TrelloRestResolver<BoardStatsArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await trelloFetch(
    networkClient.getUrl(`/1/boards/${args.id}/stats`),
    { credentials: 'same-origin' },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Board.stats',
        operationName: context.operationName,
      },
    },
  );

  if (!response.ok) {
    if (response.status === 404) {
      return null;
    } else {
      const error = await response.text();
      console.error(error);
      throw new Error(error);
    }
  }

  const model = await response.json();
  return prepareDataForApolloCache(model, rootNode, 'Board');
};

export const boardDashboardViewTileResolver: TrelloRestResolver<
  BoardDashboardViewTileArgs
> = async (
  board: {
    id: string; // idBoard
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/boards/${board.id}/dashboardViewTiles/${args.id}`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Board.DashboardViewTile',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
    } else {
      if (response.status === 404) {
        model = null;
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    return model ? prepareDataForApolloCache(model, rootNode, 'Board') : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const updateCalendarKey: TrelloRestResolver<
  MutationUpdateCalendarKeyArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}/calendarKey/generate`),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const key = await response.json();

  return prepareDataForApolloCache(key, rootNode);
};

export const messageEmailKey: TrelloRestResolver<
  MutationMessageEmailKeyArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}/emailKey/message`),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const key = await response.json();

  return prepareDataForApolloCache(key, rootNode);
};

export const updateEmailKey: TrelloRestResolver<
  MutationUpdateEmailKeyArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}/emailKey/generate`),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const key = await response.json();

  return prepareDataForApolloCache(key, rootNode);
};

export const updateEmailPosition: TrelloRestResolver<
  MutationUpdateEmailPositionArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}/emailPosition`),
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        value: args.emailPosition,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const key = await response.json();

  return prepareDataForApolloCache(key, rootNode);
};

export const updateCalendarFeedEnabledPref: TrelloRestResolver<
  MutationUpdateCalendarFeedEnabledPrefArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}`),
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        'prefs/calendarFeedEnabled': args.calendarFeedEnabled,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const removeMemberFromBoard: TrelloRestResolver<
  MutationRemoveMemberFromBoardArgs
> = async (obj, { idBoard, idMember, traceId }, context, info) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/Board/${idBoard}/members/${idMember}`,
  );

  const clientVersion = context.clientAwareness.version;
  const response = await trelloFetch(
    apiUrl,
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': clientVersion,
        ...Analytics.getTaskRequestHeaders(traceId),
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'removeMemberFromBoard',
        operationName: context.operationName,
        operationType: 'mutation',
        traceId: traceId ?? undefined,
      },
    },
  );

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });

    throw await parseNetworkError(response);
  }

  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const deleteBoard: TrelloRestResolver<MutationDeleteBoardArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/boards/${args.boardId}`),
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(args.traceId),
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
  );

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (!response.ok) {
    throw await parseNetworkError(response);
  }

  await response.json();

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const updateHiddenPluginBoardButtonShortcuts: TrelloRestResolver<
  MutationUpdateHiddenPluginBoardButtonShortcutsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const url = `/1/boards/${args.boardId}/prefs/hiddenPluginBoardButtons/?value=${args.pluginId}`;
  const method = 'PUT';

  const response = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  const key = await response.json();

  return prepareDataForApolloCache(key, rootNode);
};

export const boardInviteSecretRequestResolver: TrelloRestResolver<
  MutationCreateBoardInviteSecretArgs
> = async (_parent, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const url = `/1/boards/${args.idBoard}/invitationSecret`;
  const response = await trelloFetch(
    url,
    { credentials: 'same-origin' },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Board.invite',
        operationName: context.operationName,
      },
    },
  );

  if (!response.ok) {
    return prepareDataForApolloCache({}, rootNode);
  }

  const key = await response.json();

  return prepareDataForApolloCache(key, rootNode);
};

export const createBoardInviteSecret: TrelloRestResolver<
  MutationCreateBoardInviteSecretArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const url = `/1/boards/${args.idBoard}/invitationSecret`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      type: args.type,
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const key = await response.json();

  return prepareDataForApolloCache(key, rootNode);
};

export const disableBoardInviteSecret: TrelloRestResolver<
  MutationDisableBoardInviteSecretArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const url = `/1/boards/${args.idBoard}/invitationSecret`;
  const response = await fetch(url, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  await response.json();

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const updateBoardMemberPermission: TrelloRestResolver<
  MutationUpdateBoardMemberPermissionArgs
> = async (obj, args, context, info) => {
  const url = `/1/boards/${args.boardId}/members/${args.memberId}`;

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      type: args.type,
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }
};

export const addMemberToBoard: TrelloRestResolver<
  MutationAddMemberToBoardArgs
> = async (
  obj,
  {
    boardId,
    member,
    type = 'normal',
    allowBillableGuest = false,
    invitationMessage,
    acceptUnconfirmed = false,
    traceId = '',
  },
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const { id: memberId, email: memberEmail } = member;
  if (!memberId && !memberEmail) {
    throw new Error();
  }

  const urlParams = new URLSearchParams({ type: type! });
  memberEmail && urlParams.append('email', memberEmail);

  const trailingPath =
    (memberId ? `/${memberId}` : '') + `?${urlParams.toString()}`;

  const response = await fetch(
    networkClient.getUrl(`/1/boards/${boardId}/members${trailingPath}`),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(traceId),
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
        invitationMessage,
        allowBillableGuest,
        acceptUnconfirmed,
      }),
    },
  );

  if (!response.ok) {
    throw await parseNetworkError(response);
  }

  await response.json();

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const boardsSearchResolver: TrelloRestResolver<
  QueryBoardsSearchArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/search`);

  const params = new URLSearchParams();
  params.set('board_fields', 'all');
  params.set('query', args.query);
  params.set('boards_limit', args.limit.toString());
  params.set('modelTypes', 'boards');
  params.set('partial', (!args.query.endsWith(' ')).toString());

  const response = await trelloFetch(`${apiUrl}?${params}`, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'boardsSearch',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
  return prepareDataForApolloCache((await response.json()).boards, rootNode);
};

export const updateBoard: TrelloRestResolver<MutationUpdateBoardArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/boards/${args.boardId}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      ...args.board,
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};

export const updateBoardMyPrefs: TrelloRestResolver<
  MutationUpdateBoardMyPrefsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/boards/${args.boardId}/myPrefs/${args.pref}`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      value: args.value,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const body = await response.json();

  return prepareDataForApolloCache(body, rootNode);
};
