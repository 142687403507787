import type { SupportedFlagTypes } from '..';
import type { featureFlags } from '../data';
import { useFeatureFlag } from './useFeatureFlag';

type PermanentFlags =
  | 'atlassian-team'
  | 'billing-platform.shop-down-for-maintenance'
  | 'fep.show_somethings_wrong'
  | 'web.forcefully-reload-to-exact-version'
  | 'web.passively-reload-to-minimum-version';

export const usePermanentFeatureFlag = <T extends SupportedFlagTypes>(
  flagName: keyof Pick<typeof featureFlags, PermanentFlags>,
  defaultValue: T,
  options?: {
    sendExposureEvent?: boolean;
    attributes?: object;
  },
  // eslint-disable-next-line @trello/no-feature-flag
) => useFeatureFlag(flagName, defaultValue, options);
