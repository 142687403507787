// Generated by CoffeeScript 1.12.7
/*
  Because of the way this file was decompiled, typescript cannot detect its type
  That is why we use `@ts-ignore` in every place we reference this.
  If you're here to convert it to a class, please make sure to clean all the related `@ts-ignore`s
*/
const slice = [].slice;
const indexOf =
  [].indexOf ||
  function (item) {
    // @ts-expect-error
    for (let i = 0, l = this.length; i < l; i++) {
      // @ts-expect-error
      if (i in this && this[i] === item) return i;
    }
    return -1;
  };

import BluebirdPromise from 'bluebird';
import Hearsay from 'hearsay';
// @ts-expect-error
import Queue from 'promise-queue';
import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import Backbone from '@trello/backbone';
import { determinePossibleCardRole } from '@trello/card-roles';
import { ApiError, getApiError, parseXHRError } from '@trello/error-handling';
import { client, syncDeltaToCache } from '@trello/graphql';

import { ModelLoader } from 'app/scripts/db/model-loader';
import { ModelCache } from 'app/scripts/db/ModelCache';
import { SchedulingMixin } from 'app/scripts/lib/scheduling-mixin';
import { Util } from 'app/scripts/lib/util';
import type { Action } from 'app/scripts/models/Action';
import { ApiAjax } from 'app/scripts/network/ApiAjax';
import { classicUpdaterClient } from 'app/scripts/network/classicUpdaterClient';

export interface TrelloModelAttributes {
  id: string;
  typeName:
    | 'Action'
    | 'Attachment'
    | 'Board'
    | 'BoardInvitation'
    | 'BoardPlugin'
    | 'BoardStar'
    | 'Card'
    | 'CardComposer'
    | 'CheckItem'
    | 'Checklist'
    | 'CustomBoardBackground'
    | 'CustomEmoji'
    | 'CustomField'
    | 'CustomFieldItem'
    | 'CustomFieldOption'
    | 'CustomSticker'
    | 'Enterprise'
    | 'Invitation'
    | 'Label'
    | 'List'
    | 'Login'
    | 'Member'
    | 'Membership'
    | 'Organization'
    | 'OrganizationInvitation'
    | 'PendingOrganization'
    | 'Plugin'
    | 'PluginData'
    | 'Reaction'
    | 'SavedSearch'
    | 'Sticker'
    | 'Tag';
  url: string;
  urlRoot: string;
}

export interface NarrowModel<
  // @ts-expect-error
  T extends TrelloModel,
  K extends string,
> {
  get<Y extends K>(attr: Y): Pick<T['attributes'], K>[Y];
}

// @ts-expect-error
interface TrelloModel<T extends TrelloModelAttributes> extends Backbone.Model {
  attributes: T;

  id: string;
  cid: string;

  set<K extends keyof T>(key: K, value: T[K]): void;

  on(type: string, callback: (...values: unknown[]) => void): this;
  off(type: string, callback: (...values: unknown[]) => void): this;

  toJSON(): T;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toJSON(options: any): Partial<T>;

  update<K extends keyof T>(
    key: keyof T,
    value: T[K],
    options?: {
      skipPersist: boolean;
    },
    next?: () => void,
  ): void;

  update<K extends keyof T>(
    obj: K | (Omit<Partial<T>, 'id'> & { traceId?: string }),
    options?: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | ((err: Error | null, result: any) => void)
      | {
          debounceSaveInterval: number;
        },
  ): void;

  typeName: T['typeName'];

  setReady(): void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  __lazyCache: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  __apiQueue: any;
}

class TrelloModel<T extends TrelloModelAttributes> extends Backbone.Model {
  get _lazyCache() {
    if (!this.__lazyCache) {
      this.__lazyCache = {};
    }
    return this.__lazyCache;
  }
  get modelCache() {
    return ModelCache;
  }
  get _apiQueue() {
    if (!this.__apiQueue) {
      this.__apiQueue = new Queue(1);
    }
    return this.__apiQueue;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(attributes?: Partial<T>, options?: any) {
    super(...arguments);
    if (this.typeName) {
      ModelCache.add(this, {
        source: options?.source,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  triggerSubpropertyChangesOn(attr: any) {
    return this.listenTo(this, 'change:' + attr, (model, newAttrs) => {
      const previousAttributes = model.previousAttributes();
      const oldValue = previousAttributes?.[attr];
      const results = [];
      for (const key in newAttrs) {
        const newValue = newAttrs[key];
        if (
          oldValue /* eslint-disable-line eqeqeq */ == null ||
          newValue !== oldValue[key]
        ) {
          results.push(this.trigger('change:' + attr + '.' + key));
        }
      }
      return results;
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set(attrs: any, options?: any, ...rest: any[]) {
    let child,
      children,
      cur,
      i,
      j,
      k,
      key,
      l,
      leaf,
      len,
      len1,
      parent,
      ref1,
      ref2,
      value;
    if (_.isArray(attrs)) {
      attrs = _.find(attrs, function (a) {
        // @ts-expect-error
        return a.id === this.id || a._id === this.id;
      });
    }
    if (attrs?._id /* eslint-disable-line eqeqeq */ != null) {
      if (attrs.id /* eslint-disable-line eqeqeq */ == null) {
        attrs.id = attrs._id;
      }
      delete attrs._id;
    }
    const slashValues = (() => {
      const results = [];
      for (key in attrs) {
        value = attrs[key];
        if (key.indexOf('/') >= 0) {
          results.push([key, value]);
        }
      }
      return results;
    })();
    if (slashValues.length > 0) {
      attrs = _.clone(attrs);
      for (j = 0, len = slashValues.length; j < len; j++) {
        (ref1 = slashValues[j]), (key = ref1[0]), (value = ref1[1]);
        (ref2 = key.split('/')),
          (parent = ref2[0]),
          (children =
            3 <= ref2.length
              ? slice.call(ref2, 1, (k = ref2.length - 1))
              : ((k = 1), [])),
          (leaf = ref2[k++]);
        if (attrs[parent] /* eslint-disable-line eqeqeq */ == null) {
          attrs[parent] = _.clone(this.get(parent)) ?? {};
        }
        cur = attrs[parent];
        for (i = l = 0, len1 = children.length; l < len1; i = ++l) {
          child = children[i];
          cur = cur[child] = _.clone(cur[child]) ?? {};
        }
        cur[leaf] = value;
        delete attrs[key];
      }
    }
    if (options?.broadcast && this.id) {
      classicUpdaterClient.broadcast({
        // @ts-expect-error
        typeName: this.typeName,
        delta: { id: this.id, ...attrs },
      });
    }
    // @ts-expect-error
    return Backbone.Model.prototype.set.apply(this, [attrs, options, ...rest]);
  }

  // @ts-expect-error
  get<K extends keyof T>(attr: K): T[K] {
    if (this.attributes) {
      // @ts-expect-error
      return Backbone.Model.prototype.get.apply(this, arguments);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static lazy(map: any) {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const properties = Object.entries(map).reduce(function (properties, arg) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let key: any;
      (key = arg[0]), arg[1];
      // @ts-expect-error
      properties[key] = {
        get() {
          let collection;
          if (!this._lazyCache[key]) {
            collection = map[key].call(this);
            this.destruct(collection);
            this._lazyCache[key] = collection;
          }
          return this._lazyCache[key];
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        set(value: any) {
          throw new Error("Can't set a lazy list");
        },
      };
      return properties;
    }, {});
    return Object.defineProperties(this.prototype, properties);
  }

  destructor() {
    this.stopListening();
    this.cancelScheduled();
    // @ts-expect-error
    if (this._using /* eslint-disable-line eqeqeq */ != null) {
      // @ts-expect-error
      for (let index = 0; index < this._using.length; index++) {
        // @ts-expect-error
        const obj = this._using[index];
        if (typeof obj.destructor === 'function') {
          obj.destructor();
        }
      }
    }
    return Util.shred(this);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destroy(options?: any) {
    // @ts-expect-error
    Backbone.Model.prototype.destroy.apply(this, arguments);
    return this.destructor();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destroyWithTracing({ success, error, ...options }: any, next: any) {
    if (!options.headers) {
      options.headers = {};
    }

    const traceId = options.traceId;
    if (traceId) {
      options.headers = {
        ...options.headers,
        ...Analytics.getTaskRequestHeaders(traceId),
      };
      delete options.traceId;
    }

    this.destroy({
      ...options,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      success: (model: any, xhrResponse: any, xhrOptions: any) => {
        const trelloServerVersion =
          xhrOptions?.xhr?.getResponseHeader('X-Trello-Version');
        Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

        if (success) {
          success(model, xhrResponse, xhrOptions);
        }
        return next(null, model);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: (model: any, xhrResponse: any, xhrOptions: any) => {
        const trelloServerVersion =
          xhrOptions?.xhr?.getResponseHeader('X-Trello-Version');
        Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

        if (error) {
          error(model, xhrResponse, xhrOptions);
        }
        const status = xhrResponse !== null ? xhrResponse.status : undefined;
        const errorMessage = parseXHRError(xhrResponse);
        // @ts-expect-error
        const apiError = getApiError(status, errorMessage);
        return next(apiError, model);
      },
    });
  }

  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destruct(objs: any[] | any) {
    if (!_.isArray(objs)) {
      return this.destruct([objs]);
    } else {
      // @ts-expect-error
      if (this._using /* eslint-disable-line eqeqeq */ == null) {
        // @ts-expect-error
        this._using = [];
      }
      const results = [];
      for (let index = 0; index < objs.length; index++) {
        // @ts-expect-error
        results.push(this._using.push(objs[index]));
      }
      return results;
    }
  }

  static load = function (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any,
    modelCache: typeof ModelCache,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isCachedModelValidPredicates: any[] = [],
  ) {
    return BluebirdPromise.try(
      ((_this) => () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let existingModel: any, fields;
        if (
          modelCache /* eslint-disable-line eqeqeq */ == null ||
          id /* eslint-disable-line eqeqeq */ == null
        ) {
          return null;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onlyHas = (obj: any, allowedKeys: any) => {
          const keys = _.keys(obj);
          // @ts-expect-error
          return !_.any(keys, (key) => indexOf.call(allowedKeys, key) < 0);
        };
        if (
          (!onlyHas(payload, ['query']) ||
            !onlyHas(payload.query, ['fields'])) &&
          _.isEmpty(isCachedModelValidPredicates)
        ) {
          return null;
        }
        if (
          (existingModel = modelCache.get(
            _this,
            id,
          )) /* eslint-disable-line eqeqeq */ != null
        ) {
          fields = payload.query.fields ? payload.query.fields.split(',') : [];
          if (
            _.all(fields, (field) =>
              Object.prototype.hasOwnProperty.call(
                existingModel.attributes,
                // @ts-expect-error
                field,
              ),
            ) &&
            _.all(isCachedModelValidPredicates, (predicate) =>
              predicate(existingModel),
            )
          ) {
            return existingModel;
          }
        }
        return null;
        // @ts-expect-error
      })(this),
    ).then(
      ((_this) => (existingModel) => {
        if (existingModel /* eslint-disable-line eqeqeq */ != null) {
          return existingModel;
        }
        return ModelLoader.loadModel(_this, id, payload);
        // @ts-expect-error
      })(this),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ready(callback: any) {
    if (!callback) {
      return;
    }
    // @ts-expect-error
    if (this.isReady) {
      callback();
    } else {
      // @ts-expect-error
      if (!this.waitingForReady) {
        // @ts-expect-error
        this.waitingForReady = [];
      }
      // @ts-expect-error
      this.waitingForReady.push(callback);
    }
  }

  setReady() {
    let callback, j, len, waitingForReady;
    // @ts-expect-error
    this.isReady = true;
    // @ts-expect-error
    if (this.waitingForReady) {
      // @ts-expect-error
      waitingForReady = this.waitingForReady;
      // @ts-expect-error
      this.waitingForReady = null;
      for (j = 0, len = waitingForReady.length; j < len; j++) {
        callback = waitingForReady[j];
        if (typeof callback === 'function') {
          callback();
        }
      }
    }
  }

  update() {
    const args = arguments.length > 0 ? [...arguments] : [];

    let opts, next;
    let params = {};
    if (_.isString(args[0])) {
      const key = args[0];
      const value = args[1];
      // @ts-expect-error
      params[key] = value;
      if (args.length === 3) {
        next = args[2];
      } else {
        opts = args[2];
        next = args[3];
      }
    } else {
      params = args[0];
      if (args.length === 2) {
        next = args[1];
      } else {
        opts = args[1];
        next = args[2];
      }
    }
    if (!opts && !_.isFunction(next)) {
      opts = next;
      next = () => {};
    }
    if (!opts?.waitForServer) {
      this.set(params);
      if (this.typeName && this.id) {
        syncDeltaToCache(client, this.typeName, { id: this.id, ...params });
      }
    }
    if (opts?.traceId) {
      // @ts-expect-error
      params.traceId = opts.traceId;
    }
    if (opts?.skipPersist) {
      next?.();
      return;
    }
    if (opts?.debounceSaveInterval) {
      // @ts-expect-error
      if (!this.persistUpdateDebounced) {
        // @ts-expect-error
        this.persistUpdateDebounced = _.debounce(
          this._persistUpdate,
          opts.debounceSaveInterval,
        );
      }
      // @ts-expect-error
      return this.persistUpdateDebounced(params, next);
    } else {
      return this._persistUpdate(params, next);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _persistUpdate(params: any, next: any) {
    const traceId = params.traceId ? params.traceId : '';
    delete params.traceId;

    // @ts-expect-error
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    if (this.typeName === 'Card' && this.getCardRole && this.getCardRole()) {
      const possibleCardRole = determinePossibleCardRole({
        // @ts-expect-error
        name: this.get('name'),
        // @ts-expect-error
        description: this.get('desc'),
        // @ts-expect-error
        numAttachments: this.attachmentList.length,
        // @ts-expect-error
        numLabels: this.get('idLabels').length,
        // @ts-expect-error
        numMembers: this.get('idMembers').length,
        // @ts-expect-error
        numChecklistItems: this.get('badges').checkItems,
        // @ts-expect-error
        numCustomFieldItems: this.customFieldItemList.length,
        // @ts-expect-error
        startDate: this.get('start'),
        // @ts-expect-error
        dueDate: this.get('due'),
        // @ts-expect-error
        cover: this.get('cover'),
      });

      // On update, we don't want to assign a new cardRole; we only
      // want to unset it if the card is now a "normal" card
      if (!possibleCardRole) {
        params.cardRole = possibleCardRole;
      }
    }

    return this.api(
      {
        type: 'put',
        data: params,
        traceId,
      },
      next,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addToSet(_attr: any, _value: any, _options: any, _next: any) {
    let arg,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attr: any,
      collectionName,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delta: any,
      j,
      newValue,
      next,
      opts,
      ref1,
      traceId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      waitForServer;
    (attr = arguments[0]),
      (value = arguments[1]),
      (arg =
        4 <= arguments.length
          ? slice.call(arguments, 2, (j = arguments.length - 1))
          : ((j = 2), [])),
      (next = arguments[j++]);
    opts = arg[0];
    if (opts /* eslint-disable-line eqeqeq */ == null && !_.isFunction(next)) {
      opts = next;
      next = () => {};
    }
    (ref1 = opts ?? {}),
      (traceId = ref1.traceId),
      (waitForServer = ref1.waitForServer),
      (collectionName = ref1.collectionName);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const model = this;
    if (collectionName /* eslint-disable-line eqeqeq */ == null) {
      collectionName = attr;
    }
    if (!waitForServer) {
      newValue = _.uniq(model.get(attr) ?? []).concat(value);
      this.set(attr, newValue);
      if (this.typeName && this.id) {
        delta = {};
        delta[attr] = newValue;
        delta.id = this.id;
        syncDeltaToCache(client, this.typeName, delta);
      }
    }
    return this.api(
      {
        method: collectionName,
        data: {
          value,
        },
        traceId,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
        success: ((_this) => (resp: any, next: any) => {
          const data = {};
          // @ts-expect-error
          data[attr] = _.uniq(model.get(attr).concat(value));
          ModelCache.enqueueDelta(model, data);
          return typeof next === 'function' ? next(null, resp) : undefined;
        })(this),
      },
      next,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pull(_attr: any, _value: any, _options?: any, _next?: any) {
    let arg,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attr: any,
      collectionName,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delta: any,
      j,
      newValue,
      next,
      opts,
      ref1,
      traceId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      waitForServer;
    (attr = arguments[0]),
      (value = arguments[1]),
      (arg =
        4 <= arguments.length
          ? slice.call(arguments, 2, (j = arguments.length - 1))
          : ((j = 2), [])),
      (next = arguments[j++]);
    opts = arg[0];
    if (opts /* eslint-disable-line eqeqeq */ == null && !_.isFunction(next)) {
      opts = next;
      next = () => {};
    }
    (ref1 = opts ?? {}),
      (traceId = ref1.traceId),
      (waitForServer = ref1.waitForServer),
      (collectionName = ref1.collectionName);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const model = this;
    if (collectionName /* eslint-disable-line eqeqeq */ == null) {
      collectionName = attr;
    }
    if (!waitForServer) {
      newValue = _.without(model.get(attr) ?? [], value);
      this.set(attr, newValue);
      if (this.typeName && this.id) {
        delta = {};
        delta[attr] = newValue;
        delta.id = this.id;
        syncDeltaToCache(client, this.typeName, delta);
      }
    }
    return this.api(
      {
        method: collectionName + '/' + value,
        type: 'delete',
        traceId,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
        success: ((_this) => (resp: any, next: any) => {
          const data = {};
          // @ts-expect-error
          data[attr] = _.without(model.get(attr), value);
          ModelCache.enqueueDelta(model, data);
          return typeof next === 'function' ? next(null, resp) : undefined;
        })(this),
      },
      next,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  run(method: any, data: any, next: any) {
    return this.api(
      {
        method,
        data,
      },
      next,
    );
  }

  api(
    {
      method = '',
      type = 'post',
      data = {},
      success,
      datatype = '',
      traceId,
    }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    next = () => {},
  ) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const model = this;
    const headers = {};

    if (success /* eslint-disable-line eqeqeq */ == null) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
      success = ((_this) => (resp: any, next: any) => {
        let entry;
        if (resp.id === model.id) {
          ModelCache.enqueueDelta(model, resp);
        } else if (_.isArray(resp)) {
          entry = _.find(resp, (e) => e.id === model.id);
          if (entry /* eslint-disable-line eqeqeq */ != null) {
            ModelCache.enqueueDelta(model, entry);
          }
        }
        next(null, resp);
      })(this);
    }
    this.waitForId(
      this,
      ((_this) => () => {
        // @ts-expect-error
        let url = _this.url();
        if (method /* eslint-disable-line eqeqeq */ != null && method !== '') {
          url += '/' + method;
        }
        return BluebirdPromise.using(ModelCache.getLock(), () =>
          _this._apiQueue.add(() => {
            return new BluebirdPromise((resolve, reject) =>
              ApiAjax({
                url,
                type,
                data,
                datatype,
                traceId,
                headers,
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
                success(data: any) {
                  resolve(
                    // eslint-disable-next-line @typescript-eslint/no-shadow
                    BluebirdPromise.fromNode((next) => success(data, next)),
                  );
                },
                error(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  { status, responseText }: any,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  textStatus: any,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  error: any,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  fxDefault: any,
                ) {
                  error = getApiError(status, responseText);
                  if (
                    error instanceof ApiError.Server ||
                    error instanceof ApiError.NoResponse ||
                    error instanceof ApiError.PreconditionFailed
                  ) {
                    fxDefault();
                  }
                  reject(error);
                },
              }),
            );
          }),
        )
          .nodeify(next)
          ['catch'](() => {})
          .done();
      })(this),
    );
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggle(_attr: any, _value: any, _add: any, _arg: any, _next: any) {
    let add, arg, attr, j, next, value;
    (attr = arguments[0]),
      (value = arguments[1]),
      (add = arguments[2]),
      (arg =
        5 <= arguments.length
          ? slice.call(arguments, 3, (j = arguments.length - 1))
          : ((j = 3), [])),
      (next = arguments[j++]);
    const opts = arg[0];
    return add
      ? this.addToSet(attr, value, opts, next)
      : this.pull(attr, value, opts, next);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  snoop(attr: any) {
    if (attr /* eslint-disable-line eqeqeq */ != null) {
      return new Hearsay.ContinuousSignal(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((_this: any) => (send: any) => {
          send(_this.get('attr'));
          const update = () => send(_this.get('attr'));
          _this.listenTo(_this, 'change:' + attr, update);
          return () => _this.stopListening(_this, 'change:' + attr, update);
        })(this),
      );
    } else {
      return new Hearsay.ContinuousSignal(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ((_this) => (send: any) => {
          send(_this);
          const update = () => send(_this);
          _this.listenTo(_this, 'change', update);
          return () => _this.stopListening(_this, 'change', update);
        })(this),
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isVisibleAction(action: any) {
    return true;
  }

  getActions(): Action[] {
    const modelsForFiltering =
      1 <= arguments.length ? slice.call(arguments, 0) : [];
    return this.modelCache.all('Action').filter(
      (
        (_this) => (action: Action) =>
          action.includesModel(_this) &&
          _.all(modelsForFiltering, (model) => action.includesModel(model)) &&
          _this.isVisibleAction(action)
      )(this),
    );
  }

  // Scheduling mixin mappings
  get scheduler() {
    return SchedulingMixin;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setTimeout(fn: any, interval: number) {
    return this.scheduler.setTimeout.call(this, fn, interval);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setInterval(fn: any, interval: any) {
    return this.scheduler.setInterval.call(this, fn, interval);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestAnimationFrame(fn: any) {
    return this.scheduler.requestAnimationFrame.call(this, fn);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback(fn: any) {
    return this.scheduler.callback.call(this, fn);
  }
  cancelScheduled() {
    return this.scheduler.cancelScheduled.call(this);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defer(fn: any) {
    return this.scheduler.defer.call(this, fn);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debounce(fn: any, wait: any) {
    return this.scheduler.debounce.call(this, fn, wait);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dynamicDebounce(fn: any, getInterval: any) {
    return this.scheduler.dynamicDebounce.call(this, fn, getInterval);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callOnceAfter(fn: any, wait: any) {
    return this.scheduler.callOnceAfter.call(this, fn, wait);
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throttle(func: any, wait: any, options: any) {
    return this.scheduler.throttle.call(this, func, wait, options);
  }
}

export { TrelloModel };
