import { Deferred } from '@trello/deferred';

import type { QuickLoadOperations } from '../operation-to-quickload-url.generated';

export const deferredQuickLoads: Record<
  QuickLoadOperations,
  Deferred<null> | null
> = {
  // eslint-disable-next-line @trello/no-module-logic
  CurrentBoardInfo: new Deferred(),
  // eslint-disable-next-line @trello/no-module-logic
  CurrentBoardListsCards: new Deferred(),
  MemberHeader: null,
  MemberBoards: null,
  MemberBoardsSlim: null,
  // eslint-disable-next-line @trello/no-module-logic
  TrelloCurrentBoardInfo: new Deferred(),
  MemberQuickBoards: null,
  QuickBoardsSearch: null,
  PreloadCard: null,
  OrganizationBillingPage: null,
  WorkspaceBoardsPageMinimal: null,
  WorkspaceHomePageMinimal: null,
};
