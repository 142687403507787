/* eslint-disable eqeqeq, @trello/no-module-logic */
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import type {
  AllHTMLAttributes,
  Attributes,
  Component,
  ComponentClass,
  FunctionComponent,
  FunctionComponentElement,
  HTMLAttributes,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  RefObject,
} from 'react';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'prot... Remove this comment to see the full error message
import protoExtend from 'proto-extend';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'recu... Remove this comment to see the full error message
import recup from 'recup';
import _ from 'underscore';

import type { PIIString } from '@trello/privacy';

import { templateHelpers } from './templateHelpers';

interface DataAttributes {
  [key: `data-${string}`]: string | undefined;
}

type IconName =
  | 'add-reaction'
  | 'business-class'
  | 'check'
  | 'close'
  | 'down'
  | 'email'
  | 'gear'
  | 'help'
  | 'information'
  | 'member'
  | 'overflow-menu-horizontal'
  | 'private'
  | 'public'
  | 'star'
  | 'starred';

interface RecupRenderer {
  // Translation functions
  l(key: string[] | string, options?: { raw: boolean }): string;
  l(
    key: string[] | string,
    tokens?: Record<string, number | string>,
    options?: { raw: boolean },
  ): string;
  format(
    key: string[] | string,
    tokens?: Record<string, PIIString | number | string>,
  ): string;
  formatText(text: string): string;
  text(text: string): string;
  raw(test: string): string;

  // Utility functions
  classify(classNames: Record<string, boolean | string>): string;
  icon(
    iconName: IconName,
    attributes?: AllHTMLAttributes<HTMLSpanElement> &
      DataAttributes & {
        class?: string;
      },
  ): ReactNode;
  icon(iconName: IconName, translationKey: string): ReactNode;
  urlify(url: string): string;

  // Native elements
  a(children: () => ReactNode): ReactNode;
  a(
    attributes: AllHTMLAttributes<HTMLAnchorElement> &
      DataAttributes & {
        class?: string;
      },
    children: () => ReactNode,
  ): ReactNode;
  a(
    className: string,
    attributes: JSX.IntrinsicElements['a'] &
      DataAttributes & {
        class?: string;
      },
    children?: () => ReactNode | void,
  ): ReactNode;
  b(
    onClick: MouseEventHandler<HTMLElement>,
    attributes: AllHTMLAttributes<HTMLElement> & {
      class?: string;
    },
    children: () => ReactNode,
  ): ReactNode;
  b(
    onClick: MouseEventHandler<HTMLElement>,
    className: string,
    attributes: AllHTMLAttributes<HTMLElement> & {
      class?: string;
    },
    children: () => ReactNode,
  ): ReactNode;
  b(
    onClick: MouseEventHandler<HTMLElement>,
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    className: string,
    children: () => ReactNode,
  ): ReactNode;
  br(): ReactNode;
  button(
    attributes: AllHTMLAttributes<HTMLButtonElement> &
      DataAttributes & {
        key?: string;
        class?: string;
        ref?: (ref: RefObject<HTMLButtonElement>) => void;
      },
    children?: () => ReactNode,
  ): ReactNode;
  button(
    className: string,
    attributes: AllHTMLAttributes<HTMLButtonElement> &
      DataAttributes & {
        key?: string;
        class?: string;
        ref?: (ref: RefObject<HTMLButtonElement>) => void;
      },
    children?: () => ReactNode,
  ): ReactNode;
  div(children?: () => ReactNode | void): ReactNode;
  div(
    attributes: AllHTMLAttributes<HTMLDivElement> &
      DataAttributes & {
        class?: string;
      },
    children?: () => ReactNode | void,
  ): ReactNode;
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  div(className: string, children?: () => ReactNode | void): ReactNode;
  div(
    className: string,
    attributes: JSX.IntrinsicElements['div'] &
      DataAttributes & {
        class?: string;
      },
    children?: () => ReactNode,
  ): ReactNode;
  h1(children: () => ReactNode | void): ReactNode;
  h1(className: string, children?: () => ReactNode | void): ReactNode;
  h2(children: () => ReactNode | void): ReactNode;
  h2(className: string, children?: () => ReactNode | void): ReactNode;
  h3(children: () => ReactNode | void): ReactNode;
  h3(className: string, children?: () => ReactNode | void): ReactNode;
  h4(children: () => ReactNode | void): ReactNode;
  h4(className: string, children?: () => ReactNode | void): ReactNode;
  h5(children: () => ReactNode | void): ReactNode;
  h5(className: string, children?: () => ReactNode | void): ReactNode;
  h6(children: () => ReactNode | void): ReactNode;
  h6(className: string, children?: () => ReactNode | void): ReactNode;
  hr(className?: string): ReactNode;
  hr(
    attributes: AllHTMLAttributes<HTMLHRElement> & { class: string },
  ): ReactNode;
  img(
    attributes: AllHTMLAttributes<HTMLImageElement> & {
      class?: string;
    },
  ): ReactNode;
  img(
    className: string,
    attributes: AllHTMLAttributes<HTMLImageElement> & {
      class?: string;
    },
  ): ReactNode;
  input(
    className: string,
    attributes: AllHTMLAttributes<HTMLInputElement> & {
      class?: string;
      ref?: string | ((ref: RefObject<HTMLButtonElement>) => void);
    },
  ): ReactNode;
  input(
    attributes: AllHTMLAttributes<HTMLInputElement> & {
      class?: string;
    },
  ): ReactNode;
  label(
    attributes: AllHTMLAttributes<HTMLLabelElement> & DataAttributes,
    children: () => ReactNode | void,
  ): ReactNode;
  li(children: () => ReactNode | void): ReactNode;
  li(
    attributes: AllHTMLAttributes<HTMLLIElement> &
      DataAttributes & {
        ref?: RefObject<HTMLLIElement>;
        class?: string;
        key?: string;
      },
    children: () => ReactNode | void,
  ): ReactNode;
  // eslint-disable-next-line @typescript-eslint/unified-signatures
  li(className: string, children: () => ReactNode | void): ReactNode;
  li(
    className: string,
    attributes: AllHTMLAttributes<HTMLLIElement> & DataAttributes,
    children: () => ReactNode | void,
  ): ReactNode;
  nav(className: string, children?: () => ReactNode | void): ReactNode;
  p(children: () => ReactNode): ReactNode;
  p(className: string, children?: () => ReactNode | void): ReactNode;
  p(
    attributes: HTMLAttributes<HTMLParagraphElement> &
      DataAttributes & {
        class?: string;
      },
    children?: () => ReactNode,
  ): ReactNode;
  section(className: string, children?: () => ReactNode | void): ReactNode;
  span(className: string, children?: () => ReactNode | void): ReactNode;
  span(
    attributes: HTMLAttributes<HTMLSpanElement> &
      DataAttributes & {
        class?: string;
      },
    children?: () => ReactNode,
  ): ReactNode;
  span(children: () => ReactNode | void): ReactNode;
  span(
    className: string,
    attributes: HTMLAttributes<HTMLSpanElement> &
      DataAttributes & {
        class?: string;
        ref?: string;
        key?: string;
      },
    children?: () => ReactNode | void,
  ): ReactNode;
  strong(children: () => ReactNode): ReactNode;
  table(className: string, children: () => ReactNode): ReactNode;
  tbody(children: () => ReactNode): ReactNode;
  td(children: () => ReactNode): ReactNode;
  th(children: () => ReactNode): ReactNode;
  tr(children: () => ReactNode): ReactNode;
  ul(className: string, children: () => ReactNode | void): ReactNode;
  ul(
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    attributes: AllHTMLAttributes<HTMLUListElement> & {
      class: string;
    },
    children: () => ReactNode | void,
  ): ReactNode;
  ul(children: () => ReactNode | void): ReactNode;
  ul(
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    attributes: AllHTMLAttributes<HTMLUListElement> & DataAttributes,
    children: () => ReactNode | void,
  ): ReactNode;

  // Custom elements
  addElement(element: ReactElement): void;
  createElement<P extends object>(
    type: ComponentClass<P> | FunctionComponent<P> | string,
    props?: (Attributes & P) | null,
  ): FunctionComponentElement<P>;

  tag<T extends Component>(
    componentClass: ThisType<T>,
    props?: T['props'],
    children?: () => ReactNode | void,
  ): ReactNode;

  // Other
  renderable<P>(
    children: (props: P) => ReactNode | void,
  ): (props?: P) => ReactElement;
  render(children: () => ReactNode | void): ReactElement;
}
type RecupWithHelpers = (template?: string) => RecupRenderer;

export const recupWithHelpers: RecupWithHelpers = templateHelpers(
  protoExtend(recup, {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    b(onClick: any, ...args: any) {
      const { attrs, contents } = this.normalizeArgs(args);

      if (attrs.href != null) {
        throw Error("Can't specify an href on a `b` tag");
      }
      attrs.href = '#';

      if (attrs.onClick != null) {
        throw Error('onClick must be a positional argument to `b`');
      }

      const { disabled } = attrs;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attrs.onClick = function (e: any) {
        e.preventDefault();
        if (!disabled) {
          return onClick(e.currentTarget);
        }
      };

      return this.a(attrs, contents);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    radio(component: any, name: any, value: any, callback: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onChange = function (e: any) {
        if (e.target.checked) {
          const change = {};
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          change[name] = value;
          component.setState(change);
          if (_.isFunction(callback)) {
            callback();
          }
        }
      };
      const checked = _.isEqual(component.state[name], value);

      return this.input({ type: 'radio', name, value, checked, onChange });
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    statelessRenderable(displayName: any, fn: any) {
      return _.extend(this.renderable(fn, { displayName }));
    },
  }),
);
