import * as Types from '@trello/graphql/generated';

import { useQuickLoad } from '@trello/quickload';
import type { TrelloQueryHookOptions, TrelloLazyQueryHookOptions, TrelloSuspenseQueryHookOptions } from '@trello/quickload';
import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const WorkspaceStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"WorkspaceStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"idOrganization"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"organization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"idOrganization"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"credits"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}},{"kind":"Field","name":{"kind":"Name","value":"idEnterprise"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"offering"}},{"kind":"Field","name":{"kind":"Name","value":"paidAccount"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"trialExpiration"}}]}},{"kind":"Field","name":{"kind":"Name","value":"premiumFeatures"}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"WorkspaceStatus","document":WorkspaceStatusDocument}} as const;
export type WorkspaceStatusQueryVariables = Types.Exact<{
  idOrganization: Types.Scalars['ID']['input'];
}>;


export type WorkspaceStatusQuery = (
  { __typename: 'Query' }
  & { organization?: Types.Maybe<(
    { __typename: 'Organization' }
    & Pick<Types.Organization, 'id' | 'idEnterprise' | 'name' | 'offering' | 'premiumFeatures'>
    & { credits: Array<(
      { __typename: 'Credit' }
      & Pick<Types.Credit, 'id' | 'count' | 'type'>
    )>, paidAccount?: Types.Maybe<(
      { __typename: 'PaidAccount' }
      & Pick<Types.PaidAccount, 'trialExpiration'>
    )> }
  )> }
);

/**
 * __useWorkspaceStatusQuery__
 *
 * To run a query within a React component, call `useWorkspaceStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceStatusQuery({
 *   variables: {
 *      idOrganization: // value for 'idOrganization'
 *   },
 * });
 */
export function useWorkspaceStatusQuery(
  baseOptions: TrelloQueryHookOptions<
    WorkspaceStatusQuery,
    WorkspaceStatusQueryVariables
  > &
    (
      | { variables: WorkspaceStatusQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: WorkspaceStatusDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  const result = Apollo.useQuery<
    WorkspaceStatusQuery,
    WorkspaceStatusQueryVariables
  >(WorkspaceStatusDocument, options);
  // reconstruct result because modifying the useQuery result actually changes apollo behavior because of memoization
  const trelloResult = { ...result };
  if (isQuickLoading) {
    trelloResult.loading = true;
  }

  return trelloResult;
}
export function useWorkspaceStatusLazyQuery(
  baseOptions?: TrelloLazyQueryHookOptions<
    WorkspaceStatusQuery,
    WorkspaceStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceStatusQuery,
    WorkspaceStatusQueryVariables
  >(WorkspaceStatusDocument, options);
}
export function useWorkspaceStatusSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | TrelloSuspenseQueryHookOptions<
        WorkspaceStatusQuery,
        WorkspaceStatusQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    WorkspaceStatusQuery,
    WorkspaceStatusQueryVariables
  >(WorkspaceStatusDocument, options);
}
export type WorkspaceStatusQueryHookResult = ReturnType<
  typeof useWorkspaceStatusQuery
>;
export type WorkspaceStatusLazyQueryHookResult = ReturnType<
  typeof useWorkspaceStatusLazyQuery
>;
export type WorkspaceStatusSuspenseQueryHookResult = ReturnType<
  typeof useWorkspaceStatusSuspenseQuery
>;
export type WorkspaceStatusQueryResult = Apollo.QueryResult<
  WorkspaceStatusQuery,
  WorkspaceStatusQueryVariables
>;
