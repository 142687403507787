import { Analytics } from '@trello/atlassian-analytics';
import { clientVersion } from '@trello/config';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { featureFlagClient } from '@trello/feature-flag-client';
import { fetch, trelloFetch } from '@trello/fetch';
import {
  MemberErrorExtensions,
  NetworkError,
  parseNetworkError,
} from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import { atlassianOrganizationSsoUrl } from '../atlassianOrganizationSsoUrl';
import type {
  Member_Atlassian_Organization,
  MutationAcceptDeveloperTermsArgs,
  MutationAddBoardStarArgs,
  MutationAddCampaignArgs,
  MutationAddMessageDismissedArgs,
  MutationAddOneTimeMessagesDismissedArgs,
  MutationAddSavedSearchArgs,
  MutationDeleteCustomStickerArgs,
  MutationDeleteOneTimeMessagesDismissedArgs,
  MutationDeleteSavedSearchArgs,
  MutationEnableMemberProfileSyncArgs,
  MutationRemoveBoardStarArgs,
  MutationResendVerificationEmailArgs,
  MutationUnblockMemberProfileSyncArgs,
  MutationUpdateBoardStarArgs,
  MutationUpdateCampaignArgs,
  MutationUpdateMarketingOptInArgs,
  MutationUpdateMemberProfileArgs,
  MutationUpdateNotificationChannelSettingsArgs,
  MutationUpdateNotificationEmailFrequencyArgs,
  MutationUploadCustomStickerArgs,
  QueryMemberCardsArgs,
  QueryMemberIdFromUsernameArgs,
  QueryMemberSearchArgs,
  QueryNotificationChannelSettingsArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { JSONObject, TrelloRestResolver } from '../types';

export const addBoardStar: TrelloRestResolver<
  MutationAddBoardStarArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/member/${args.memberId}/boardStars`),
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(args.traceId),
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
        idBoard: args.boardId,
        pos: args.pos,
      }),
    },
  );

  const member = await response.json();

  return prepareDataForApolloCache(member, rootNode);
};

export const removeBoardStar: TrelloRestResolver<
  MutationRemoveBoardStarArgs
> = async (obj, args, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/member/${args.memberId}/boardStars/${args.boardStarId}`,
  );
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });

    throw await parseNetworkError(response);
  }

  return true;
};

export const updateBoardStar: TrelloRestResolver<
  MutationUpdateBoardStarArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(
      `/1/member/${args.memberId}/boardStars/${args.boardStarId}`,
    ),
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
        pos: args.pos,
      }),
    },
  );

  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }

  const json = await response.json();

  return prepareDataForApolloCache(json, rootNode);
};

export const addSavedSearch: TrelloRestResolver<
  MutationAddSavedSearchArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/member/${args.memberId}/savedSearches`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...args.savedSearch,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const deleteSavedSearch: TrelloRestResolver<
  MutationDeleteSavedSearchArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/member/${args.memberId}/savedSearches/${args.savedSearchId}`,
  );
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const enableMemberProfileSync: TrelloRestResolver<
  MutationEnableMemberProfileSyncArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const searchParams = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/member/${args.memberId}/atlassianAccount/clearProfileSyncBlock`,
  );

  const postResponse = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: searchParams.toString(),
  });

  if (!postResponse.ok) {
    // Sometimes the server sends us just the string as the error, however
    // sometimes the server also sends a JSON error with the key 'message'
    // as the error
    let message = await postResponse.text(); // Parse it as text
    let data = null;

    try {
      data = JSON.parse(message); // Try to parse it as json
      message = data.message;
      // eslint-disable-next-line no-empty
    } catch {}

    sendNetworkErrorEvent({
      url: apiUrl,
      response: await postResponse.clone().text(),
      status: postResponse.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(postResponse);
  }

  // Re-fetch the Member so that the return will correctly update the apollo cache
  const refetchUrl = networkClient.getUrl(
    `/1/member/${args.memberId}?fields=aaBlockSyncUntil`,
  );
  const response = await trelloFetch(refetchUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'enableMemberProfileSync',
      operationType: 'query',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: refetchUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const member = await response.json();
  return prepareDataForApolloCache(member, rootNode);
};

export const unblockMemberProfileSync: TrelloRestResolver<
  MutationUnblockMemberProfileSyncArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const searchParams = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  const networkClient = getNetworkClient();

  const postResponse = await fetch(
    networkClient.getUrl(
      `/1/member/${args.memberId}/atlassianAccount/migrationComplete`,
    ),
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: searchParams.toString(),
    },
  );

  if (!postResponse.ok) {
    // Sometimes the server sends us just the string as the error, however
    // sometimes the server also sends a JSON error with the key 'message'
    // as the error
    let message = await postResponse.text(); // Parse it as text
    let data = null;

    try {
      data = JSON.parse(message); // Try to parse it as json
      message = data.message;
      // eslint-disable-next-line no-empty
    } catch {}

    throw new Error(message);
  }

  // Re-fetch the Member so that the return will correctly update the apollo cache
  const response = await trelloFetch(
    networkClient.getUrl(
      `/1/member/${args.memberId}?fields=aaBlockSyncUntil,oneTimeMessagesDismissed,requiresAaOnboarding`,
    ),
    undefined,
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'unblockMemberProfileSync',
        operationType: 'query',
        operationName: context.operationName,
      },
    },
  );
  if (!response.ok) {
    throw new Error(
      `Invalid response status ${response.status} from GET ${response.url}`,
    );
  }
  const member = await response.json();

  return prepareDataForApolloCache(member, rootNode);
};

// Internal endpoint for development and trelloinc members
export const deleteOneTimeMessagesDismissed: TrelloRestResolver<
  MutationDeleteOneTimeMessagesDismissedArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const searchParams = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );

  searchParams.set('value', args.message);

  const networkClient = getNetworkClient();

  const response = await fetch(
    networkClient.getUrl(
      `/1/members/${args.memberId}/oneTimeMessagesDismissed`,
    ),
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: searchParams,
    },
  );

  const member = await response.json();

  return prepareDataForApolloCache(member, rootNode);
};

export const addOneTimeMessagesDismissed: TrelloRestResolver<
  MutationAddOneTimeMessagesDismissedArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const searchParams = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );

  searchParams.set('value', args.messageId);

  const networkClient = getNetworkClient();

  const response = await fetch(
    networkClient.getUrl(
      `/1/members/${args.memberId}/oneTimeMessagesDismissed`,
    ),
    {
      method: 'POST',
      credentials: 'include',
      body: searchParams,
      headers: {
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
    },
  );

  const member = await response.json();

  return prepareDataForApolloCache(member, rootNode);
};

export const addMessageDismissed: TrelloRestResolver<
  MutationAddMessageDismissedArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/members/${args.memberId}/messagesDismissed`,
  );
  const response = await trelloFetch(
    apiUrl,
    {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        name: args.name,
        ...getCsrfRequestPayload(),
        lastDismissed: args.lastDismissed,
      }),
      headers: {
        'X-Trello-Client-Version': context.clientAwareness.version,
        'Content-Type': 'application/json',
      },
    },
    {
      clientVersion,
      networkRequestEventAttributes: {
        operationName: context.operationName,
        resolver: 'addMessageDismissed',
        operationType: 'mutation',
        source: 'graphql',
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const member = await response.json();

  return prepareDataForApolloCache(member, rootNode);
};

export const memberAgreementsResolver: TrelloRestResolver<object> = async (
  member: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/members/${member.id}/agreements`);

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Member.agreements',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
    } else {
      if (response.status === 404) {
        model = null;
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    return model ? prepareDataForApolloCache(model, rootNode, 'Member') : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const memberAtlassianOrganizationsResolver: TrelloRestResolver<
  object
> = async (
  member: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;
  let lean = false;

  const linkToAtlassianOrgV2 = featureFlagClient.get(
    'enterprise.link-to-atlassian-org-v2-web',
    false,
  );

  if (linkToAtlassianOrgV2) {
    lean = true;
  }
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/members/${member.id}/atlassianOrganizations?lean=${lean}`,
  );

  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Member.atlassianOrganizations',
      operationName: context.operationName,
    },
  });

  if (response.ok) {
    model = await response.json();
  } else if (response.status === 404) {
    return null;
  } else {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  // attempt to populate SSO URLs for any administered Orgs without a value
  await Promise.all(
    model.map(async (org: Member_Atlassian_Organization) => {
      /*
        Server always sends back an `ssoUrl` field.
        We make it the default value for `ssoUrls` here
        to minimize risk and for backwards compatibility.
      */
      org.ssoUrls = org.ssoUrl ? [org.ssoUrl] : [];

      if (org.isIdentityAdmin) {
        try {
          const ssoUrls = await atlassianOrganizationSsoUrl(
            member.id,
            org.id,
            context,
          );
          if (ssoUrls.length) {
            org.ssoUrls = ssoUrls;
          }
        } catch (e) {
          console.error(e);
          // If the request to fetch the SAML configuration fails,
          // we don't want to re-throw that error from here, because there
          // could be successful requests for other AtlOrgs. But we do want
          // to surface that error in the UI. So we set flexAuthError and use
          // that in the component to show an error message.
          org.flexAuthError = true;
        }
      }
    }),
  );
  return model ? prepareDataForApolloCache(model, rootNode, 'Member') : model;
};

export const acceptDeveloperTerms: TrelloRestResolver<
  MutationAcceptDeveloperTermsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/members/${args.memberId}/agreements`);

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        agreementType: 'developer-terms',
        ...getCsrfRequestPayload(),
      }),
    });

    if (response.ok) {
      model = await response.json();
    } else {
      throw new Error(
        `An error occurred while resolving a GraphQL mutation. (status: ${response.status}, statusText: ${response.statusText})`,
      );
    }

    return model ? prepareDataForApolloCache(model, rootNode) : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const updateMemberProfile: TrelloRestResolver<
  MutationUpdateMemberProfileArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const operationNameInUrl = featureFlagClient.get(
    'fep.operation-name-in-url',
    false,
  );
  const queryString = operationNameInUrl
    ? `?operationName=${context.operationName}`
    : '';
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/members/me${queryString}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...args.profile,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const addCampaign: TrelloRestResolver<MutationAddCampaignArgs> = async (
  obj,
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl('/1/members/me/campaigns');
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...(args || {}),
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const updateCampaign: TrelloRestResolver<
  MutationUpdateCampaignArgs
> = async (obj, args, context) => {
  const { campaignId, ...rest } = args;
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/members/me/campaigns/${campaignId}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...(rest || {}),
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }
};

export const resendVerificationEmail: TrelloRestResolver<
  MutationResendVerificationEmailArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl('/resendValidate');

  const { email, confirmReturnUrl } = args;

  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      email,
      confirmReturnUrl,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    model = await response.json();

    if (model.badEmail) {
      throw new NetworkError('bad email', {
        code: MemberErrorExtensions.BAD_EMAIL,
        status: 400,
      });
    } else if (model.alreadyConfirmed) {
      throw new NetworkError('already confirmed', {
        code: MemberErrorExtensions.ALREADY_CONFIRMED_EMAIL,
        status: 400,
      });
    }
  } else {
    throw new Error(
      `An error occurred while resolving a GraphQL mutation. (status: ${response.status}, statusText: ${response.statusText})`,
    );
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

// Very specific resolver to use on /members/id/cards
export const memberCardsResolver: TrelloRestResolver<
  QueryMemberCardsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { id, limit, before, modifiedSince, sort, traceId } = args;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/members/${id}/cards`);

  const params = new URLSearchParams();
  params.set('filter', 'visible');
  params.set('stickers', 'true');
  params.set('attachments', 'true');
  params.set('members', 'true');

  if (limit) {
    params.set('limit', limit.toString());
  }
  if (before) {
    params.set('before', before);
  }
  if (modifiedSince) {
    params.set('modifiedSince', modifiedSince.toString());
  }
  if (sort) {
    params.set('sort', sort);
  }

  const response = await trelloFetch(`${apiUrl}?${params}`, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'memberCards',
      operationName: context.operationName,
      traceId: traceId ? traceId : undefined,
    },
  });

  if (response.ok) {
    const model = await response.json();
    return prepareDataForApolloCache(model, rootNode);
  } else {
    throw new Error(
      `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
    );
  }
};

export const memberIdFromUsernameResolver: TrelloRestResolver<
  QueryMemberIdFromUsernameArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const { username } = args;
  // eslint-disable-next-line @trello/disallow-altering-privacy-fields
  const apiUrl = networkClient.getUrl(`/1/members/${username}?fields=id`);

  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'memberIdFromUsername',
      operationName: context.operationName,
    },
  });

  if (response.ok) {
    const model = await response.json();
    return prepareDataForApolloCache(model, rootNode);
  } else {
    throw new Error(
      `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
    );
  }
};

export const memberSearchResolver: TrelloRestResolver<
  QueryMemberSearchArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/search/members`);

  const params = new URLSearchParams();

  Object.entries(args).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      params.set(key, value.toString());
    }
  });

  const response = await trelloFetch(`${apiUrl}?${params}`, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'memberSearch',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const updateMarketingOptIn: TrelloRestResolver<
  MutationUpdateMarketingOptInArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { optedIn, prompt } = args;
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/members/me`);

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        'marketingOptIn/optedIn': optedIn,
        'marketingOptIn/displayText': prompt,
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'updateMarketingOptIn',
        operationName: context.operationName,
        operationType: 'mutation',
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const member = await response.json();
  return prepareDataForApolloCache(member, rootNode);
};

export const updateNotificationEmailFrequency: TrelloRestResolver<
  MutationUpdateNotificationEmailFrequencyArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { id, frequency } = args;
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/members/${id}?prefs%2FminutesBetweenSummaries=${frequency}`,
  );

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(args.traceId),
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'updateNotificationEmailFrequency',
        operationName: context.operationName,
        operationType: 'mutation',
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const member = await response.json();
  return prepareDataForApolloCache(member, rootNode);
};

export const updateNotificationChannelSettings: TrelloRestResolver<
  MutationUpdateNotificationChannelSettingsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { blockedKeys, channel } = args;
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    '/1/members/me/notificationChannelSettings',
  );

  const response = await trelloFetch(
    apiUrl,
    {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
      },
      body: JSON.stringify({
        channel,
        blockedKeys,
        ...getCsrfRequestPayload(),
      }),
    },
    {
      clientVersion,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'updateNotificationChannelSettings',
        operationName: context.operationName,
        operationType: 'mutation',
      },
    },
  );

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const member = await response.json();
  return prepareDataForApolloCache(member, rootNode);
};

export const notificationChannelSettingsResolver: TrelloRestResolver<
  QueryNotificationChannelSettingsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  let member = null;
  const apiUrl = networkClient.getUrl(
    `/1/members/me/notificationChannelSettings/${args.channel}`,
  );

  const getResponse = await trelloFetch(
    apiUrl,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
    {
      clientVersion,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'notificationChannelSettings',
        operationName: context.operationName,
      },
    },
  );

  if (getResponse.ok) {
    member = await getResponse.json();
  } else {
    // Since the notification settings mongo collection is not being
    // backfilled for all users, the first time a user comes to the
    // notifications settings screen, the API request to get their
    // settings will return 404. The endpoint does not have the
    // option to do a POST so we do a PUT instead. This flow will
    // only happen once per user.
    if (getResponse.status === 404) {
      const createResponse = await trelloFetch(apiUrl, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-Trello-Client-Version': context.clientAwareness.version,
        },
        body: JSON.stringify({
          channel: args.channel,
          blockedKeys: [],
          ...getCsrfRequestPayload(),
        }),
      });
      if (createResponse.ok) {
        member = await createResponse.json();
      } else {
        sendNetworkErrorEvent({
          url: apiUrl,
          response: await createResponse.clone().text(),
          status: createResponse.status,
          operationName: context.operationName,
        });
        throw await parseNetworkError(getResponse);
      }
    } else {
      sendNetworkErrorEvent({
        url: apiUrl,
        response: await getResponse.clone().text(),
        status: getResponse.status,
        operationName: context.operationName,
      });
      throw await parseNetworkError(getResponse);
    }
  }

  return member ? prepareDataForApolloCache(member, rootNode) : member;
};

export const uploadCustomSticker: TrelloRestResolver<
  MutationUploadCustomStickerArgs
> = async (obj, { memberId, workspaceId, file, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/member/${memberId}/customStickers`);
  const formData = new FormData();
  formData.set('file', file);
  formData.set('idOrganization', workspaceId || '');

  const csrfPayload = getCsrfRequestPayload({ fallbackValue: '' });
  if (csrfPayload.dsc) {
    formData.set('dsc', csrfPayload.dsc);
  }

  const request = new Promise<JSONObject>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('POST', apiUrl);
    const tracingHeaders = Analytics.getTaskRequestHeaders(traceId);
    for (const header in tracingHeaders) {
      const value = tracingHeaders[header as keyof typeof tracingHeaders];
      xhr.setRequestHeader(header, value);
    }
    xhr.onload = () => {
      const trelloServerVersion = xhr.getResponseHeader('X-Trello-Version');
      Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

      if (xhr.status >= 200 && xhr.status < 300) {
        const response = JSON.parse(xhr.response);
        resolve(response);
      } else {
        sendNetworkErrorEvent({
          url: apiUrl,
          response: xhr.statusText,
          status: xhr.status,
          operationName: context.operationName,
        });

        reject(xhr.statusText);
      }
    };
    xhr.onerror = () => {
      const trelloServerVersion = xhr.getResponseHeader('X-Trello-Version');
      Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

      sendNetworkErrorEvent({
        url: apiUrl,
        response: xhr.statusText,
        status: xhr.status,
        operationName: context.operationName,
      });

      reject({ status: xhr.status, statusText: xhr.statusText });
    };

    xhr.send(formData);
  });

  try {
    const sticker = await request;
    return prepareDataForApolloCache(sticker, rootNode);
  } catch (e) {
    throw new Error((e as Error).message);
  }
};

export const deleteCustomSticker: TrelloRestResolver<
  MutationDeleteCustomStickerArgs
> = async (obj, args, context) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/member/${args.memberId}/customStickers/${args.stickerId}`,
  );
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });

    throw await parseNetworkError(response);
  }

  return true;
};
