import type { FunctionComponent } from 'react';
import { Suspense, useCallback, useState } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { useFeatureGate } from '@trello/feature-gate-client';
import { Key, Scope, useShortcut } from '@trello/keybindings';
import { useLazyComponent } from '@trello/use-lazy-component';

import { Null } from 'app/src/components/Null';

export const LazyQuickSwitcher: FunctionComponent = () => {
  const QuickSwitcher = useLazyComponent(
    () => import(/* webpackChunkName: "quick-switcher" */ './QuickSwitcher'),
    { namedImport: 'QuickSwitcher' },
  );

  const [isInitialized, setIsInitialized] = useState(false);
  const initializeQuickSwitcher = useCallback(() => {
    setIsInitialized(true);
  }, []);

  const { value: isSplitScreenEnabled } = useFeatureGate(
    'phoenix_fg_split_screen_nav',
  );

  useShortcut(initializeQuickSwitcher, {
    scope: Scope.Global,
    key: Key.b,
    // Once the quick switcher has been initialized, disable this shortcut in
    // favor of the one within the QuickSwitcher component directly.
    enabled: !isInitialized && !isSplitScreenEnabled,
  });

  if (!isInitialized) {
    return null;
  }

  return (
    <ErrorBoundary
      tags={{ ownershipArea: 'trello-web-eng', feature: 'Quick Switcher' }}
      errorHandlerComponent={Null}
    >
      <ChunkLoadErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <QuickSwitcher />
        </Suspense>
      </ChunkLoadErrorBoundary>
    </ErrorBoundary>
  );
};
