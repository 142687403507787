import { TrelloSessionStorage, TrelloStorage } from '@trello/storage';

import { SharedState } from './SharedState';

interface PersistentSharedStateOptions {
  storageKey: string | (() => string);
  /**
   * Whether to subscribe to changes in other tabs/windows
   * and sync the value in real time. Defaults to true
   */
  syncAcrossBrowser?: boolean;
  /**
   * Whether to use session storage instead of local storage.
   * Defaults to false
   */
  session?: boolean;
}

/**
 * Represents a shared value (can be observed and updated in a shared context)
 * that's also persisted in a lightweight way (think local/session store).
 *
 * See {@link SharedState}.
 */
export class PersistentSharedState<
  Value extends boolean | number | object | string,
> extends SharedState<Value> {
  #hasSyncLock: boolean = false;

  constructor(
    initialValue: Value,
    {
      storageKey,
      session = false,
      syncAcrossBrowser = true,
    }: PersistentSharedStateOptions,
  ) {
    super(initialValue);

    // eslint-disable-next-line @trello/enforce-variable-case
    const Storage = session ? TrelloSessionStorage : TrelloStorage;

    const thisKey =
      typeof storageKey === 'function' ? storageKey() : storageKey;

    const existingValue = Storage.get(thisKey);
    if (existingValue !== null && existingValue !== initialValue) {
      this.setValue(existingValue);
    }

    this.subscribe((value) => {
      if (this.#hasSyncLock) {
        return;
      }

      Storage.set(thisKey, value);
    });

    if (syncAcrossBrowser) {
      Storage.listenSyncedAcrossBrowser(({ key, newValue }) => {
        if (key !== thisKey) {
          return;
        }

        const value =
          newValue !== null ? (JSON.parse(newValue) as Value) : initialValue;

        this.#hasSyncLock = true;
        this.setValue(value);
        this.#hasSyncLock = false;
      });
    }
  }
}
