import { arePartialsEqual, isPlainObject } from '@trello/objects';

export interface Updater<Value> {
  (value: Value): Value;
}

interface Listener<Value> {
  (value: Value, previousValue: Value): void;
}

export interface SharedStateOptions {
  /** @default false */
  onlyUpdateIfChanged?: boolean;
}

function isUpdater<Value>(value: unknown): value is Updater<Value> {
  return typeof value === 'function';
}

function isPrimitiveValue(value: unknown) {
  if (value === null) {
    return true;
  }

  if (typeof value === 'object' || typeof value === 'function') {
    return false;
  }

  return true;
}

/**
 * Represents a shared value that can be observed and updated in a shared
 * context. Any value changes will alert the registered listeners.
 *
 * See [TRELLOFE - Sharing state between architectures](https://hello.atlassian.net/wiki/spaces/TRELLOFE/blog/2020/10/06/900192334/Sharing+state+between+architectures)
 *
 * Related: {@link PersistentSharedState}
 */
export class SharedState<Value> {
  value: Value;

  #initialValue: Value;
  #listeners: Set<Listener<Value>> = new Set();

  constructor(initialValue: Value) {
    this.value = initialValue;
    this.#initialValue = initialValue;
  }

  /**
   * Sets a new value and updates all listeners.
   */
  setValue(nextValue: Partial<Value> | Updater<Value> | Value): void {
    const previousValue = this.value;
    if (isUpdater(nextValue)) {
      this.value = nextValue(this.value) as Value;
    } else if (isPlainObject(nextValue)) {
      this.value = { ...this.value, ...nextValue };
    } else {
      this.value = nextValue;
    }

    for (const listener of this.#listeners) {
      listener(this.value, previousValue);
    }
  }

  /**
   * Subscribes the listener for any changes to the value.
   * @param listener
   * @returns a function which can be used to unsubscribe the listener from updates.
   */
  subscribe(
    listener: Listener<Value>,
    options: SharedStateOptions = {},
  ): () => void {
    const fx: Listener<Value> = (nextState, previousState) => {
      let shouldUpdate = true;
      if (options.onlyUpdateIfChanged) {
        if (isPrimitiveValue(nextState)) {
          shouldUpdate = nextState !== previousState;
        } else if (isPlainObject(nextState)) {
          shouldUpdate = !arePartialsEqual(nextState, previousState);
        }
      }

      if (shouldUpdate) {
        listener(nextState, previousState);
      }
    };

    this.#listeners.add(fx);

    return () => {
      this.#listeners.delete(fx);
    };
  }

  /**
   * For testing purposes only. DO NOT USE IN APPLICATION CODE.
   * @private
   * */
  reset() {
    this.value = this.#initialValue;
    this.#listeners = new Set();
  }
}
