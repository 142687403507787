import { getApiGatewayUrl } from '@trello/api-gateway';
import { Analytics } from '@trello/atlassian-analytics';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { featureFlagClient } from '@trello/feature-flag-client';
import { fetch, trelloFetch } from '@trello/fetch';
import type { ErrorExtensionsType } from '@trello/graphql-error-handling';
import {
  NetworkError,
  parseNetworkError,
} from '@trello/graphql-error-handling';
import { getNetworkClient } from '@trello/network-client';
import { getCsrfRequestPayload } from '@trello/session-cookie/csrf';

import type {
  MutationAddFreeTrialArgs,
  MutationAddMemberToOrgArgs,
  MutationAddTagArgs,
  MutationBulkDisableWorkspacePowerUpArgs,
  MutationBulkEnableWorkspacePowerUpArgs,
  MutationCopyBoardToOrgArgs,
  MutationCreateEnterpriseJoinRequestArgs,
  MutationCreateOrganizationArgs,
  MutationCreateOrganizationInviteSecretArgs,
  MutationDeactivateMemberForWorkspaceArgs,
  MutationDeleteEnterpriseJoinRequestArgs,
  MutationDeleteOrganizationArgs,
  MutationDeleteOrganizationInviteSecretArgs,
  MutationDeleteOrganizationLogoArgs,
  MutationInviteMemberToJiraArgs,
  MutationReactivateMemberForWorkspaceArgs,
  MutationRemoveMemberFromWorkspaceArgs,
  MutationRemoveOrganizationOrgInviteRestrictDomainArgs,
  MutationStartOrganizationExportArgs,
  MutationToggleAutoUpgradeArgs,
  MutationUnlinkSlackTeamArgs,
  MutationUpdateOrganizationArgs,
  MutationUpdateOrganizationAtlassianIntelligenceEnabledArgs,
  MutationUpdateOrganizationBoardInvitationRestrictArgs,
  MutationUpdateOrganizationEnterpriseBoardDeleteRestrictArgs,
  MutationUpdateOrganizationEnterpriseBoardVisibilityRestrictArgs,
  MutationUpdateOrganizationOrgBoardDeleteRestrictArgs,
  MutationUpdateOrganizationOrgBoardVisibilityRestrictArgs,
  MutationUpdateOrganizationOrgInviteRestrictArgs,
  MutationUpdateOrganizationPermissionLevelArgs,
  MutationUpdateOrganizationPrivateBoardDeleteRestrictArgs,
  MutationUpdateOrganizationPrivateBoardVisibilityRestrictArgs,
  MutationUpdateOrganizationPublicBoardDeleteRestrictArgs,
  MutationUpdateOrganizationPublicBoardVisibilityRestrictArgs,
  MutationUpdateOrganizationSlackTeamInvitationRestrictionArgs,
  MutationUpdateOrganizationSlackTeamLinkRestrictionArgs,
  MutationUpdateWorkspaceMemberPermissionArgs,
  MutationUploadOrganizationImageArgs,
  Organization_Stats_Cards_GroupBy,
  Organization_StatsCardsArgs,
  OrganizationCardsArgs,
  OrganizationNewBillableGuestsArgs,
  QueryOrganizationInviteSecretArgs,
  QueryOrganizationMemberCardsArgs,
} from '../generated';
import { isQueryInfo } from '../isQueryInfo';
import { prepareDataForApolloCache } from '../prepareDataForApolloCache';
import type { JSONObject, TrelloRestResolver, TypedJSONObject } from '../types';

export const organizationNewBillableGuestsResolver: TrelloRestResolver<
  OrganizationNewBillableGuestsArgs
> = async (
  organization: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${organization.id}/newBillableGuests/${args.boardId}`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Organization.newBillableGuests',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
    } else {
      if (response.status === 404) {
        model = null;
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    return model
      ? prepareDataForApolloCache(model, rootNode, 'Organization')
      : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const organizationOwnedPluginsResolver: TrelloRestResolver<
  object
> = async (
  organization: {
    id: string;
  },
  args,
  context,
  info,
): Promise<TypedJSONObject> => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${organization.id}/ownedPlugins`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Organization.ownedPlugins',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
    } else {
      if ([401, 404, 449].includes(response.status)) {
        model = null;
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    return model
      ? prepareDataForApolloCache(model, rootNode, 'Organization')
      : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const organizationJiraEligibleMembersResolver: TrelloRestResolver<
  object
> = async (
  organization: {
    id: string;
  },
  args,
  context,
  info,
): Promise<TypedJSONObject> => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${organization.id}/jiraEligibleMembers`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Organization.jiraEligibleMembers',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
    } else {
      if ([401, 404, 449].includes(response.status)) {
        model = null;
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    return model
      ? prepareDataForApolloCache(model, rootNode, 'Organization')
      : model;
  } catch (err) {
    console.error(err);
    return model;
  }
};

export const organizationLabelNamesResolver: TrelloRestResolver<
  object
> = async (
  organization: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${organization.id}/stats/labelNames/`,
  );

  try {
    const response = await trelloFetch(apiUrl, undefined, {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'Organization_Stats.labelNames',
        operationName: context.operationName,
      },
    });

    if (response.ok) {
      model = await response.json();
    } else {
      if (response.status === 404) {
        model = null;
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    const mappedModel =
      Object.entries(model['data'] as JSONObject)
        // Map the labelNames returned from Apollo to LabelFilterOrgLabels
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .map(([name, orgLabel]: [string, JSONObject]) => ({
          name,
          ...orgLabel,
        })) || [];

    return prepareDataForApolloCache(
      {
        incomplete: model.incomplete,
        stats: mappedModel,
      },
      rootNode,
      'Organization_Stats',
    );
  } catch (err) {
    console.error(err);
    return model;
  }
};

// eslint-disable-next-line @trello/enforce-variable-case
const GroupByToIdField: Record<Organization_Stats_Cards_GroupBy, string> = {
  label: 'idLabel',
  labelName: 'labelName',
  member: 'idMember',
};

const assignGroupById = (
  obj: JSONObject,
  id: string,
  groupBy: Organization_Stats_Cards_GroupBy,
) => {
  const idField = GroupByToIdField[groupBy];
  return idField ? { [idField]: id, ...obj } : obj;
};

export const organizationCardStatsResolver: TrelloRestResolver<
  Organization_StatsCardsArgs
> = async (
  organizationStats: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { groupBy, idBoards, idMembers, idLabels, dueDateUntil, dueDateSince } =
    args;
  const params = new URLSearchParams({
    ...(groupBy && { groupBy }),
    ...(idBoards && idBoards.length > 0 && { idBoards: idBoards.join(',') }),
    ...(idMembers &&
      idMembers.length > 0 && { idMembers: idMembers.join(',') }),
    ...(idLabels && idLabels.length > 0 && { idLabels: idLabels.join(',') }),
    ...(dueDateUntil && { dueDateUntil }),
    ...(dueDateSince && { dueDateSince }),
    includeIds: 'overdue,upcoming',
  });
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${organizationStats.id}/stats/cards?${params.toString()}`,
  );
  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Organization_Stats.cards',
      operationName: context.operationName,
    },
  });
  const json = await response.json();
  const cards = groupBy
    ? Object.entries((json['data'] || {}) as JSONObject).map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ([key, value]: [string, JSONObject]) => {
          return assignGroupById(value, key, groupBy);
        },
      )
    : [json['data'] || {}];
  return prepareDataForApolloCache(
    {
      incomplete: json.incomplete,
      stats: cards,
    },
    rootNode,
    'Organization_Stats',
  );
};

export const organizationStatsResolver: TrelloRestResolver<object> = (
  organization: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  return prepareDataForApolloCache(
    {
      id: organization.id,
    },
    rootNode,
    'Organization',
  );
};

export const createOrganization: TrelloRestResolver<
  MutationCreateOrganizationArgs
> = async (obj, args, context, info) => {
  const { ...fields } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl('/1/organizations');
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      ...(fields || {}),
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const updateOrganization: TrelloRestResolver<
  MutationUpdateOrganizationArgs
> = async (obj, args, context, info) => {
  const { orgId, ...fields } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/organizations/${orgId}`);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...(fields || {}),
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const updateOrganizationPermissionLevel: TrelloRestResolver<
  MutationUpdateOrganizationPermissionLevelArgs
> = async (obj, args, context, info) => {
  const { orgId, visibility, traceId } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${orgId}/prefs/permissionLevel`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      value: visibility,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const updateOrganizationOrgInviteRestrict: TrelloRestResolver<
  MutationUpdateOrganizationOrgInviteRestrictArgs
> = async (obj, args, context, info) => {
  const { orgId, restriction, traceId } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${orgId}/prefs/orgInviteRestrict`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      value: restriction,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const removeOrganizationOrgInviteRestrictDomain: TrelloRestResolver<
  MutationRemoveOrganizationOrgInviteRestrictDomainArgs
> = async (obj, args, context, info) => {
  const { orgId, domain, traceId } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${orgId}/prefs/orgInviteRestrict`,
  );
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      value: domain,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const updateOrganizationAtlassianIntelligenceEnabled: TrelloRestResolver<
  MutationUpdateOrganizationAtlassianIntelligenceEnabledArgs
> = async (obj, args, context, info) => {
  const { orgId, atlassianIntelligenceEnabled, traceId } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${orgId}/prefs/atlassianIntelligenceEnabled`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      value: atlassianIntelligenceEnabled,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

const updateOrganizationBoardCreateRestrict: (
  path: `/${string}`,
) => TrelloRestResolver<
  | MutationUpdateOrganizationEnterpriseBoardVisibilityRestrictArgs
  | MutationUpdateOrganizationOrgBoardVisibilityRestrictArgs
  | MutationUpdateOrganizationPrivateBoardVisibilityRestrictArgs
  | MutationUpdateOrganizationPublicBoardVisibilityRestrictArgs
> = (path) => async (obj, args, context, info) => {
  const { visibility } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(path);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      value: visibility,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const updateOrganizationPublicBoardVisibilityRestrict: TrelloRestResolver<
  MutationUpdateOrganizationPublicBoardVisibilityRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardVisibilityRestrict/public`;
  return updateOrganizationBoardCreateRestrict(path)(obj, args, context, info);
};

export const updateOrganizationEnterpriseBoardVisibilityRestrict: TrelloRestResolver<
  MutationUpdateOrganizationEnterpriseBoardVisibilityRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardVisibilityRestrict/enterprise`;
  return updateOrganizationBoardCreateRestrict(path)(obj, args, context, info);
};

export const updateOrganizationOrgBoardVisibilityRestrict: TrelloRestResolver<
  MutationUpdateOrganizationOrgBoardVisibilityRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardVisibilityRestrict/org`;
  return updateOrganizationBoardCreateRestrict(path)(obj, args, context, info);
};

export const updateOrganizationPrivateBoardVisibilityRestrict: TrelloRestResolver<
  MutationUpdateOrganizationPrivateBoardVisibilityRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardVisibilityRestrict/private`;
  return updateOrganizationBoardCreateRestrict(path)(obj, args, context, info);
};

const updateOrganizationBoardDeleteRestrict: (
  path: `/${string}`,
) => TrelloRestResolver<
  | MutationUpdateOrganizationEnterpriseBoardDeleteRestrictArgs
  | MutationUpdateOrganizationOrgBoardDeleteRestrictArgs
  | MutationUpdateOrganizationPrivateBoardDeleteRestrictArgs
  | MutationUpdateOrganizationPublicBoardDeleteRestrictArgs
> = (path) => async (obj, args, context, info) => {
  const { deletion } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(path);
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      value: deletion,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const updateOrganizationPublicBoardDeleteRestrict: TrelloRestResolver<
  MutationUpdateOrganizationPublicBoardDeleteRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardDeleteRestrict/public`;
  return updateOrganizationBoardDeleteRestrict(path)(obj, args, context, info);
};

export const updateOrganizationEnterpriseBoardDeleteRestrict: TrelloRestResolver<
  MutationUpdateOrganizationEnterpriseBoardDeleteRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardDeleteRestrict/enterprise`;
  return updateOrganizationBoardDeleteRestrict(path)(obj, args, context, info);
};

export const updateOrganizationOrgBoardDeleteRestrict: TrelloRestResolver<
  MutationUpdateOrganizationOrgBoardDeleteRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardDeleteRestrict/org`;
  return updateOrganizationBoardDeleteRestrict(path)(obj, args, context, info);
};

export const updateOrganizationPrivateBoardDeleteRestrict: TrelloRestResolver<
  MutationUpdateOrganizationPrivateBoardDeleteRestrictArgs
> = async (obj, args, context, info) => {
  const path: `/${string}` = `/1/organizations/${args.orgId}/prefs/boardDeleteRestrict/private`;
  return updateOrganizationBoardDeleteRestrict(path)(obj, args, context, info);
};

export const updateOrganizationBoardInvitationRestrict: TrelloRestResolver<
  MutationUpdateOrganizationBoardInvitationRestrictArgs
> = async (obj, args, context, info) => {
  const { orgId, restriction, traceId } = args;
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${orgId}/prefs/boardInviteRestrict`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      value: restriction,
      ...getCsrfRequestPayload(),
    }),
  });

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });
  throw await parseNetworkError(response);
};

export const deleteOrganization: TrelloRestResolver<
  MutationDeleteOrganizationArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.idOrganization}`,
  );
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (response.ok) {
    return prepareDataForApolloCache(await response.json(), rootNode);
  }

  sendNetworkErrorEvent({
    url: apiUrl,
    response: await response.clone().text(),
    status: response.status,
    operationName: context.operationName,
  });

  throw await parseNetworkError(response);
};

export const copyBoardToOrg: TrelloRestResolver<
  MutationCopyBoardToOrgArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  // Pre-fetch before mutation
  let response = await trelloFetch(
    networkClient.getUrl(`/1/boards/${args.boardId}`),
    {
      credentials: 'same-origin',
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'copyBoardToOrg',
        operationType: 'mutation',
        operationName: context.operationName,
      },
    },
  );
  const copy = await response.json();

  const searchParams = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );

  searchParams.set('name', copy.name);
  searchParams.set('idOrganization', args.organizationId);
  searchParams.set('idBoardSource', args.boardId);
  searchParams.set('creationMethod', 'assisted');
  searchParams.set('keepFromSource', 'cards');
  searchParams.set('prefs_permissionLevel', 'org');

  response = await fetch(networkClient.getUrl(`/1/boards/`), {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: searchParams,
  });

  const board = await response.json();

  return prepareDataForApolloCache(board, rootNode);
};

export const addMemberToOrg: TrelloRestResolver<
  MutationAddMemberToOrgArgs
> = async (
  obj,
  { orgId, user, invitationMessage, type = 'normal', traceId = '' },
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${orgId}/members${user?.id ? `/${user.id}` : ''}`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      ...(user?.id ? { acceptUnconfirmed: true } : { email: user?.email }),
      type,
      invitationMessage,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const updateWorkspaceMemberPermission: TrelloRestResolver<
  MutationUpdateWorkspaceMemberPermissionArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${args.workspaceId}/members/${args.memberId}`,
  );

  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(args.traceId),
    },
    body: JSON.stringify({
      type: args.type,
      ...getCsrfRequestPayload(),
    }),
  });

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(args.traceId, trelloServerVersion);

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const data = await response.json();
  return prepareDataForApolloCache(data, rootNode);
};

export const removeMemberFromWorkspace: TrelloRestResolver<
  MutationRemoveMemberFromWorkspaceArgs
> = async (
  obj,
  { orgId, user, type = 'normal', traceId = '' },
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(
      `/1/organizations/${orgId}/members${user?.id ? `/${user.id}` : ''}`,
    ),
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(traceId),
      },
      body: JSON.stringify({
        ...(user?.id ? { acceptUnconfirmed: true } : { email: user?.email }),
        type,
        ...getCsrfRequestPayload(),
      }),
    },
  );

  if (!response.ok) {
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache({ success: true }, rootNode);
};

export const uploadOrganizationImage: TrelloRestResolver<
  MutationUploadOrganizationImageArgs
> = async (obj, { orgId, file }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const formData = new FormData();
  formData.set('file', file);
  const csrfPayload = getCsrfRequestPayload({
    fallbackValue: '',
  });
  if (csrfPayload.dsc) {
    formData.set('dsc', csrfPayload.dsc);
  }
  const networkClient = getNetworkClient();

  // We need to use XHR in order to track upload progress
  const request = new Promise<JSONObject>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.open('POST', networkClient.getUrl(`/1/organizations/${orgId}/logo`));
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        const response = JSON.parse(xhr.response);
        resolve(response);
      } else {
        reject(xhr.response);
      }
    };
    xhr.onerror = () => {
      reject(xhr.response);
    };

    xhr.send(formData);
  });

  try {
    const organization = await request;
    return prepareDataForApolloCache(organization, rootNode);
  } catch (err) {
    let error: { message: string; error: string };
    try {
      error = JSON.parse(err as string);
    } catch (e) {
      error = {
        message: err as string,
        error: 'UNKNOWN_ERROR',
      };
    }

    throw new NetworkError(error?.message, {
      code: (error?.error as ErrorExtensionsType) || 'UNKNOWN_ERROR',
      status: 400,
    });
  }
};

export const deleteOrganizationLogo: TrelloRestResolver<
  MutationDeleteOrganizationLogoArgs
> = async (obj, { orgId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/organizations/${orgId}/logo`);
  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  // Re-fetching to populate Apollo cache
  const organization = await trelloFetch(
    networkClient.getUrl(`/1/organizations/${orgId}`),
    undefined,
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'deleteOrganizationLogo',
        operationType: 'mutation',
        operationName: context.operationName,
      },
    },
  );
  return prepareDataForApolloCache(await organization.json(), rootNode);
};

export const createEnterpriseJoinRequest: TrelloRestResolver<
  MutationCreateEnterpriseJoinRequestArgs
> = async (obj, { traceId, workspaceId, enterpriseId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${workspaceId}/enterpriseJoinRequest`,
  );

  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      value: enterpriseId,
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const data = await response.json();

  return prepareDataForApolloCache(data, rootNode);
};

export const deleteEnterpriseJoinRequest: TrelloRestResolver<
  MutationDeleteEnterpriseJoinRequestArgs
> = async (obj, { traceId, workspaceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${workspaceId}/enterpriseJoinRequest`,
  );

  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache({ enterpriseJoinRequest: null }, rootNode);
};

export const toggleAutoUpgrade: TrelloRestResolver<
  MutationToggleAutoUpgradeArgs
> = async (obj, { upgrade, orgId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${orgId}/freeTrial/setAutoUpgrade`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      upgrade,
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  const data = await response.json();
  return prepareDataForApolloCache(data, rootNode);
};

export const addFreeTrial: TrelloRestResolver<
  MutationAddFreeTrialArgs
> = async (obj, { orgId, via, count, traceId }, context, info) => {
  const networkClient = getNetworkClient();
  const redeemUrl = networkClient.getUrl(`/1/organizations/${orgId}/freeTrial`);

  const redeemResponse = await trelloFetch(
    redeemUrl,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
        ...(via ? { via } : {}),
        ...(count ? { count } : {}),
      }),
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'addFreeTrial',
        operationName: context.operationName,
        operationType: 'mutation',
        traceId: traceId ?? undefined,
      },
    },
  );

  if (!redeemResponse.ok) {
    sendNetworkErrorEvent({
      url: redeemUrl,
      response: await redeemResponse.clone().text(),
      status: redeemResponse.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(redeemResponse);
  }

  const paidAccountUrl = networkClient.getUrl(
    `/1/organizations/${orgId}?fields=credits,offering&paidAccount=true&paidAccount_fields=all`,
  );

  const paidAccountResponse = await trelloFetch(paidAccountUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'addFreeTrial',
      operationName: context.operationName,
      operationType: 'mutation',
    },
  });

  if (!paidAccountResponse.ok) {
    sendNetworkErrorEvent({
      url: redeemUrl,
      response: await paidAccountResponse.clone().text(),
      status: paidAccountResponse.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(paidAccountResponse);
  }

  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const model = await paidAccountResponse.json();
  return prepareDataForApolloCache(model, rootNode);
};

export const organizationCardsResolver: TrelloRestResolver<
  OrganizationCardsArgs
> = async (
  organization: {
    id: string;
  },
  args,
  context,
  info,
): Promise<TypedJSONObject> => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  let model = null;

  try {
    const {
      idBoards,
      limit,
      cursor,
      due,
      start,
      date,
      dueComplete,
      sortBy,
      idLists,
      labels,
      idMembers,
    } = args;
    const params = new URLSearchParams({
      idBoards: idBoards.join(','),
      ...(limit && { limit: `${limit}` }),
      ...(cursor && { cursor }),
      ...(start && { start }),
      ...(date && { date }),
      ...(due && { due }),
      ...(dueComplete !== null && { dueComplete: `${dueComplete}` }),
      ...(sortBy && { sortBy }),
      ...(idLists && { idLists: idLists.join(',') }),
      ...(labels && { labels: labels.join(',') }),
      ...(idMembers && { idMembers: idMembers.join(',') }),
    });

    const networkClient = getNetworkClient();

    const apiUrl = networkClient.getUrl(
      `/1/organizations/${organization.id}/cards`,
    );

    const response = await trelloFetch(
      apiUrl,
      {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          ...getCsrfRequestPayload(),
          ...Object.fromEntries(params),
        }),
      },
      {
        clientVersion: context.clientAwareness.version,
        // The reason we dedupe here is despite this being a POST request we are
        // only using it to fetch data and consider it to be idempotent for our
        // purposes
        deduplicate: true,
        networkRequestEventAttributes: {
          source: 'graphql',
          resolver: 'Organization.cards',
          operationName: context.operationName,
        },
      },
    );

    if (response.ok) {
      model = await response.json();
    } else {
      if ([400, 401, 404, 422, 449].includes(response.status)) {
        model = null;
        throw new Error(await response.text());
      } else {
        throw new Error(
          `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
        );
      }
    }

    return model
      ? prepareDataForApolloCache(model, rootNode, 'Organization')
      : model;
  } catch (err) {
    console.error(err);
    throw new Error((err as Error).toString());
  }
};

// Very specific resolver to use on /members/id/cards
export const organizationMemberCardsResolver: TrelloRestResolver<
  QueryOrganizationMemberCardsArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const { id, idMember } = args;

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organization/${id}/members/${idMember}/cards`,
  );

  const params = new URLSearchParams();
  params.set(
    'fields',
    'badges,closed,dateLastActivity,due,dueComplete,idAttachmentCover,idList,idBoard,idMembers,idShort,labels,name,url',
  );
  params.set('board', 'true');
  params.set('board_fields', 'name,closed,memberships');
  params.set('list', 'true');
  params.set('attachments', 'true');
  params.set('stickers', 'all');
  params.set('members', 'true');
  params.set(
    'member_fields',
    'activityBlocked,avatarUrl,bio,bioData,confirmed,fullName,idEnterprise,idMemberReferrer,initials,memberType,nonPublic,products,url,username',
  );

  const response = await trelloFetch(`${apiUrl}?${params}`, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'organizationMemberCards',
      operationName: context.operationName,
    },
  });

  if (response.ok) {
    const model = await response.json();
    return prepareDataForApolloCache(model, rootNode);
  } else {
    throw new Error(
      `An error occurred while resolving a GraphQL query. (status: ${response.status}, statusText: ${response.statusText})`,
    );
  }
};

export const organizationSlackAssociationResolver: TrelloRestResolver<
  object
> = async (
  organization: {
    id: string;
  },
  args,
  context,
  info,
) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const apiUrl = getApiGatewayUrl(
    `/slack/trello/${organization.id}/association`,
  );
  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'organizationSlackAssociation',
      operationName: context.operationName,
    },
  });

  if (response.ok) {
    const model = await response.json();
    return prepareDataForApolloCache(model, rootNode, 'Organization');
  } else {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });

    return null;
  }
};

export const updateOrganizationSlackTeamInvitationRestriction: TrelloRestResolver<
  MutationUpdateOrganizationSlackTeamInvitationRestrictionArgs
> = async (obj, args, context, info) => {
  const { orgId, slackTeamId, selfJoin } = args;
  const apiUrl = getApiGatewayUrl(
    `/slack/trello/${orgId}/selfJoin?idSlackTeam=${slackTeamId}&value=${selfJoin.toString()}`,
  );
  const response = await trelloFetch(
    apiUrl,
    {
      method: 'PUT',
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'updateOrganizationSlackTeamInvitationRestriction',
        operationName: context.operationName,
      },
    },
  );

  if (response.ok) {
    // There is no response body for this request.
    // It just returns a plain text "OK" response
    return null;
  } else {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });

    throw await parseNetworkError(response);
  }
};

export const updateOrganizationSlackTeamLinkRestriction: TrelloRestResolver<
  MutationUpdateOrganizationSlackTeamLinkRestrictionArgs
> = async (obj, args, context, info) => {
  const { orgId, adminOnlyLinking } = args;
  const apiUrl = getApiGatewayUrl(
    `/slack/trello/${orgId}/adminOnlyLinking?value=${adminOnlyLinking.toString()}`,
  );
  const response = await trelloFetch(
    apiUrl,
    {
      method: 'PUT',
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'updateOrganizationSlackTeamLinkRestriction',
        operationName: context.operationName,
      },
    },
  );

  if (response.ok) {
    // There is no response body for this request.
    // It just returns a plain text "OK" response
    return null;
  } else {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });

    throw await parseNetworkError(response);
  }
};

export const unlinkSlackTeam: TrelloRestResolver<
  MutationUnlinkSlackTeamArgs
> = async (obj, args, context, info) => {
  const { orgId, slackTeamId } = args;
  const apiUrl = getApiGatewayUrl(
    `/slack/trello/${orgId}/association?idSlackTeam=${slackTeamId}`,
  );
  const response = await trelloFetch(
    apiUrl,
    {
      method: 'DELETE',
    },
    {
      clientVersion: context.clientAwareness.version,
      networkRequestEventAttributes: {
        source: 'graphql',
        resolver: 'unlinkSlackTeam',
        operationName: context.operationName,
      },
    },
  );

  if (response.ok) {
    // There is no response body for this request.
    // It just returns a plain text "OK" response
    return null;
  } else {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });

    throw await parseNetworkError(response);
  }
};

export const addTag: TrelloRestResolver<MutationAddTagArgs> = async (
  obj,
  { orgId, tag },
  context,
) => {
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(`/1/organizations/${orgId}/tags`);
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      name: tag,
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return true;
};

export const bulkDisableWorkspacePowerUp: TrelloRestResolver<
  MutationBulkDisableWorkspacePowerUpArgs
> = async (obj, { orgId, pluginId, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/organizations/${orgId}/plugins/${pluginId}`),
    {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(traceId),
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
  );

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

  if (!response.ok) {
    throw await parseNetworkError(response);
  }

  const json = await response.json();

  // Since this request could be potentially updating
  // many boards, it may be updated through a heartbeat
  // rather than through this request
  if (json.updateQueued) {
    return json;
  }

  return prepareDataForApolloCache({ plugins: json }, rootNode);
};

export const bulkEnableWorkspacePowerUp: TrelloRestResolver<
  MutationBulkEnableWorkspacePowerUpArgs
> = async (obj, { orgId, pluginId, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const response = await fetch(
    networkClient.getUrl(`/1/organizations/${orgId}/plugins/${pluginId}`),
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Trello-Client-Version': context.clientAwareness.version,
        ...Analytics.getTaskRequestHeaders(traceId),
      },
      body: JSON.stringify({
        ...getCsrfRequestPayload(),
      }),
    },
  );

  const trelloServerVersion = response.headers.get('X-Trello-Version');
  Analytics.setTrelloServerVersion(traceId, trelloServerVersion);

  if (!response.ok) {
    throw await parseNetworkError(response);
  }

  const json = await response.json();

  // Since this request could be potentially updating
  // many boards, it may be updated through a heartbeat
  // rather than through this request
  if (json.updateQueued) {
    return json;
  }

  return prepareDataForApolloCache({ plugins: json }, rootNode);
};

export const createOrganizationInviteSecret: TrelloRestResolver<
  MutationCreateOrganizationInviteSecretArgs
> = async (obj, { idOrganization, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const operationNameInUrl = featureFlagClient.get(
    'fep.operation-name-in-url',
    false,
  );
  const queryString = operationNameInUrl
    ? `?operationName=${context.operationName}`
    : '';
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${idOrganization}/invitationSecret${queryString}`,
  );

  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const deleteOrganizationInviteSecret: TrelloRestResolver<
  MutationDeleteOrganizationInviteSecretArgs
> = async (obj, { idOrganization, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const operationNameInUrl = featureFlagClient.get(
    'fep.operation-name-in-url',
    false,
  );
  const queryString = operationNameInUrl
    ? `?operationName=${context.operationName}`
    : '';
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${idOrganization}/invitationSecret${queryString}`,
  );

  const response = await fetch(apiUrl, {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const organizationInviteSecretResolver: TrelloRestResolver<
  QueryOrganizationInviteSecretArgs
> = async (obj, { idOrganization }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${idOrganization}/invitationSecret`,
  );

  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Organization.invite',
      operationName: context.operationName,
    },
  });

  // If we get a 404 response, we know that the user has disabled the workspace invite link
  // We want to repopulate the cache so that the two workspace invite UIs rerender
  if (response.status === 404) {
    return prepareDataForApolloCache({ secret: null, type: null }, rootNode);
  }

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const workspaceGuestsResolver: TrelloRestResolver<{
  workspaceId: string;
}> = async (obj, { workspaceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${workspaceId}/collaborators`,
  );

  const response = await trelloFetch(apiUrl, undefined, {
    clientVersion: context.clientAwareness.version,
    networkRequestEventAttributes: {
      source: 'graphql',
      resolver: 'Organization.guests',
      operationName: context.operationName,
    },
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const deactivateMemberForWorkspace: TrelloRestResolver<
  MutationDeactivateMemberForWorkspaceArgs
> = async (obj, { workspaceId, memberId, traceId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'true');

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${workspaceId}/members/${memberId}/deactivated`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
      ...Analytics.getTaskRequestHeaders(traceId),
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const reactivateMemberForWorkspace: TrelloRestResolver<
  MutationReactivateMemberForWorkspaceArgs
> = async (obj, { workspaceId, memberId }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const params = new URLSearchParams(
    getCsrfRequestPayload({ fallbackValue: '' }),
  );
  params.set('value', 'false');

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${workspaceId}/members/${memberId}/deactivated`,
  );
  const response = await fetch(apiUrl, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: params,
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const inviteMemberToJira: TrelloRestResolver<
  MutationInviteMemberToJiraArgs
> = async (obj, { workspaceId, memberIds }, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];

  const networkClient = getNetworkClient();
  const apiUrl = networkClient.getUrl(
    `/1/organizations/${workspaceId}/jiraInvite`,
  );
  const response = await fetch(apiUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      inviteMembers: memberIds,
    }),
  });

  if (!response.ok) {
    sendNetworkErrorEvent({
      url: apiUrl,
      response: await response.clone().text(),
      status: response.status,
      operationName: context.operationName,
    });
    throw await parseNetworkError(response);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};

export const startOrganizationExport: TrelloRestResolver<
  MutationStartOrganizationExportArgs
> = async (obj, args, context, info) => {
  const rootNode = isQueryInfo(info) ? info.field : info.fieldNodes[0];
  const response = await fetch(`/1/organizations/${args.id}/exports`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': context.clientAwareness.version,
    },
    body: JSON.stringify({
      ...getCsrfRequestPayload(),
      attachments: args.attachments,
    }),
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.error);
  }

  return prepareDataForApolloCache(await response.json(), rootNode);
};
