import { useCallback } from 'react';

import { useSharedStateSelector } from '@trello/shared-state';
import { workspaceState } from '@trello/workspace-state';

import type { FeatureGateKeys } from './data/featureGates';
import {
  getFeatureGatesClientCache,
  getWorkspaceCacheKey,
} from './state/featureGatesClientSharedState';
import { useIsFeatureGateClientInitializeCompleted } from './useIsFeatureGateClientInitializeCompleted';

export const useFeatureGateWithRefresh = (featureGateName: FeatureGateKeys) => {
  const isFeatureGateClientInitializeCompleted =
    useIsFeatureGateClientInitializeCompleted();

  const { workspaceId } = useSharedStateSelector(
    workspaceState,
    useCallback(
      (state) => ({
        workspaceId: state.workspaceId,
      }),
      [],
    ),
  );

  const sharedStateGateValue = useSharedStateSelector(
    getFeatureGatesClientCache(),
    useCallback(
      (updateState) => {
        return updateState[getWorkspaceCacheKey(workspaceId)]?.gates[
          featureGateName
        ];
      },
      [featureGateName, workspaceId],
    ),
  );

  return {
    loading: !isFeatureGateClientInitializeCompleted,
    value: sharedStateGateValue ?? false,
  };
};
