import * as Types from '@trello/graphql/generated';

import { useQuickLoad } from '@trello/quickload';
import type { TrelloQueryHookOptions, TrelloLazyQueryHookOptions, TrelloSuspenseQueryHookOptions } from '@trello/quickload';
import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const MyMessagesDismissedDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"MyMessagesDismissed"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"idMember"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"member"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"idMember"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"messagesDismissed"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"_id"}},{"kind":"Field","name":{"kind":"Name","value":"count"}},{"kind":"Field","name":{"kind":"Name","value":"lastDismissed"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"MyMessagesDismissed","document":MyMessagesDismissedDocument}} as const;
export type MyMessagesDismissedQueryVariables = Types.Exact<{
  idMember: Types.Scalars['ID']['input'];
}>;


export type MyMessagesDismissedQuery = (
  { __typename: 'Query' }
  & { member?: Types.Maybe<(
    { __typename: 'Member' }
    & Pick<Types.Member, 'id'>
    & { messagesDismissed?: Types.Maybe<Array<(
      { __typename: 'Member_MessageDismissed' }
      & Pick<Types.Member_MessageDismissed, 'id' | '_id' | 'count' | 'lastDismissed' | 'name'>
    )>> }
  )> }
);

/**
 * __useMyMessagesDismissedQuery__
 *
 * To run a query within a React component, call `useMyMessagesDismissedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMessagesDismissedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMessagesDismissedQuery({
 *   variables: {
 *      idMember: // value for 'idMember'
 *   },
 * });
 */
export function useMyMessagesDismissedQuery(
  baseOptions: TrelloQueryHookOptions<
    MyMessagesDismissedQuery,
    MyMessagesDismissedQueryVariables
  > &
    (
      | { variables: MyMessagesDismissedQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const isQuickLoading = useQuickLoad({
    waitOn: baseOptions?.waitOn || ['None'],
    document: MyMessagesDismissedDocument,
    skip: baseOptions?.skip,
  });
  const options = {
    ...defaultOptions,
    ...baseOptions,
    skip: isQuickLoading || baseOptions?.skip,
  };
  const result = Apollo.useQuery<
    MyMessagesDismissedQuery,
    MyMessagesDismissedQueryVariables
  >(MyMessagesDismissedDocument, options);
  // reconstruct result because modifying the useQuery result actually changes apollo behavior because of memoization
  const trelloResult = { ...result };
  if (isQuickLoading) {
    trelloResult.loading = true;
  }

  return trelloResult;
}
export function useMyMessagesDismissedLazyQuery(
  baseOptions?: TrelloLazyQueryHookOptions<
    MyMessagesDismissedQuery,
    MyMessagesDismissedQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MyMessagesDismissedQuery,
    MyMessagesDismissedQueryVariables
  >(MyMessagesDismissedDocument, options);
}
export function useMyMessagesDismissedSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | TrelloSuspenseQueryHookOptions<
        MyMessagesDismissedQuery,
        MyMessagesDismissedQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    MyMessagesDismissedQuery,
    MyMessagesDismissedQueryVariables
  >(MyMessagesDismissedDocument, options);
}
export type MyMessagesDismissedQueryHookResult = ReturnType<
  typeof useMyMessagesDismissedQuery
>;
export type MyMessagesDismissedLazyQueryHookResult = ReturnType<
  typeof useMyMessagesDismissedLazyQuery
>;
export type MyMessagesDismissedSuspenseQueryHookResult = ReturnType<
  typeof useMyMessagesDismissedSuspenseQuery
>;
export type MyMessagesDismissedQueryResult = Apollo.QueryResult<
  MyMessagesDismissedQuery,
  MyMessagesDismissedQueryVariables
>;
