import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const BoardClosedCardsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"BoardClosedCards"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"parentId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"board"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"parentId"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"cards"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"filter"},"value":{"kind":"EnumValue","value":"closed"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"BoardClosedCards","document":BoardClosedCardsDocument}} as const;
export type BoardClosedCardsQueryVariables = Types.Exact<{
  parentId: Types.Scalars['ID']['input'];
}>;


export type BoardClosedCardsQuery = (
  { __typename: 'Query' }
  & { board?: Types.Maybe<(
    { __typename: 'Board' }
    & Pick<Types.Board, 'id'>
    & { cards: Array<(
      { __typename: 'Card' }
      & Pick<Types.Card, 'id'>
    )> }
  )> }
);

/**
 * __useBoardClosedCardsQuery__
 *
 * To run a query within a React component, call `useBoardClosedCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardClosedCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardClosedCardsQuery({
 *   variables: {
 *      parentId: // value for 'parentId'
 *   },
 * });
 */
export function useBoardClosedCardsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BoardClosedCardsQuery,
    BoardClosedCardsQueryVariables
  > &
    (
      | { variables: BoardClosedCardsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BoardClosedCardsQuery, BoardClosedCardsQueryVariables>(
    BoardClosedCardsDocument,
    options,
  );
}
export function useBoardClosedCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BoardClosedCardsQuery,
    BoardClosedCardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BoardClosedCardsQuery,
    BoardClosedCardsQueryVariables
  >(BoardClosedCardsDocument, options);
}
export function useBoardClosedCardsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BoardClosedCardsQuery,
        BoardClosedCardsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    BoardClosedCardsQuery,
    BoardClosedCardsQueryVariables
  >(BoardClosedCardsDocument, options);
}
export type BoardClosedCardsQueryHookResult = ReturnType<
  typeof useBoardClosedCardsQuery
>;
export type BoardClosedCardsLazyQueryHookResult = ReturnType<
  typeof useBoardClosedCardsLazyQuery
>;
export type BoardClosedCardsSuspenseQueryHookResult = ReturnType<
  typeof useBoardClosedCardsSuspenseQuery
>;
export type BoardClosedCardsQueryResult = Apollo.QueryResult<
  BoardClosedCardsQuery,
  BoardClosedCardsQueryVariables
>;
