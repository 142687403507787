import type { FunctionComponent } from 'react';
import { Suspense, useCallback, useState } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { HeaderButton } from '@trello/header-primitives';
import { useLazyComponent } from '@trello/use-lazy-component';

import { AtlassianAppSwitcherIcon } from './AtlassianAppSwitcherIcon';

import styles from './AtlassianAppSwitcherButton.less';

export const LazyAtlassianAppSwitcherButton: FunctionComponent = () => {
  const [shouldRender, setShouldRender] = useState(false);

  const AtlassianAppSwitcherButton = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "atlassian-app-switcher-button" */ './AtlassianAppSwitcherButton'
      ),
    { namedImport: 'AtlassianAppSwitcherButton', preload: shouldRender },
  );

  const onClickPlaceholderButton = useCallback(() => {
    setShouldRender(true);
  }, []);

  const renderPlaceholderButton = useCallback(
    () => (
      <HeaderButton
        className={styles.appSwitcher}
        icon={<AtlassianAppSwitcherIcon />}
        onClick={onClickPlaceholderButton}
      />
    ),
    [onClickPlaceholderButton],
  );

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-cross-flow',
        feature: 'Atlassian Switcher',
      }}
    >
      {shouldRender ? (
        <ChunkLoadErrorBoundary fallback={null}>
          <Suspense fallback={renderPlaceholderButton()}>
            <AtlassianAppSwitcherButton />
          </Suspense>
        </ChunkLoadErrorBoundary>
      ) : (
        renderPlaceholderButton()
      )}
    </ErrorBoundary>
  );
};
